import { ConfigProvider } from 'antd';
import { useTheme } from 'styled-components';

import i18n, { AppLangs } from '../i18n';

type AntdConfigProps = {
  children: React.ReactNode;
};

export const AntdConfig: React.FC<AntdConfigProps> = ({ children }) => {
  const theme = useTheme();
  const currentLanguage = i18n.language;

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: currentLanguage === AppLangs.AR ? 'Zain' : 'Roboto',
        },
        components: {
          Menu: {
            darkItemSelectedBg: theme.sub,
            darkItemSelectedColor: `${theme.lightMain} !important`,
            darkItemHoverBg: theme.lightMain,
          },
          Tabs: {
            inkBarColor: theme.black,
            itemActiveColor: `${theme.black} !important`,
            fontWeightStrong: 700,
            itemHoverColor: 'green !important',
            itemColor: `${theme.red} !important`,
            itemSelectedColor: `${theme.main} !important`,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
