import { Modal } from '../../../components/modal/Modal';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { useAppSelector } from '../../../redux/store';

interface PreviewFormProps {
  open: boolean;
  onClose: VoidFunction;
}

export const PreviewFormModal: React.FC<PreviewFormProps> = ({
  open,
  onClose,
}) => {
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const formLink = `${process.env.REACT_APP_API_URL}/form/${currentForm?._id}/generate`;

  if (!currentForm) {
    return null;
  }

  return (
    <Modal key={currentForm._id} open={open} onClose={onClose}>
      <iframe
        width="300"
        height="400"
        style={{ border: 'none' }}
        src={formLink}
        title="preview form"
      ></iframe>
    </Modal>
  );
};
