import { faGear } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { SubHeader } from '../../components/SubHeader';
import { AtiraTour } from '../../components/tour/AtiraTour';
import { WidgetKind } from '../../model/widget/types/WidgetKid.enum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { widgetSliceSelectors } from '../../redux/widget/widget.selector';
import { widgetActions } from '../../redux/widget/widget.slice';
import { WidgetCreateWidget } from './components/widgets/WidgetCreateWidget';
import { WidgetCustomizeDrawer } from './components/widgets/WidgetCustomizeDrawer';
import { WidgetLatestEntries } from './components/widgets/WidgetLatestEntries';
import { WidgetStats } from './components/widgets/WidgetStats';
import { WidgetWrapper } from './components/widgets/WidgetWrapper';

const Wrapper = styled(Flex)`
  padding: 0 1rem;
  width: 100%;
`;

export const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const widgets = useAppSelector(widgetSliceSelectors.selectUserWidgets);

  const WidgetComponent = {
    [WidgetKind.STATS]: WidgetStats,
    [WidgetKind.LAST_ENTRIES]: WidgetLatestEntries,
  };

  useEffect(() => {
    const fetchWidgets = async () => {
      try {
        const response = await dispatch(
          widgetActions.getUserWidgets({ userId }),
        ).unwrap();

        console.log({ widgets: response });

        if (!response?.length) {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          timer.current = setTimeout(fetchWidgets, 1000);
        } else {
          if (timer.current) {
            clearTimeout(timer.current);
          }
        }
      } catch (e: any) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      }
    };

    fetchWidgets();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [dispatch, userId]);

  return (
    <Flex flexDirection="column" overflow="hidden">
      <SubHeader
        title={t('subheader.dashboard')}
        icon={faGear}
        buttonTitle={t('common.customize')}
        onClick={() => setOpen(true)}
      />

      <Wrapper>
        {!widgets.length ? (
          <SpinnerFullScreen />
        ) : (
          <Row gutter={{ lg: 0 }} style={{ width: '100%' }}>
            {[...widgets]
              .filter(({ active }) => active)
              .sort((a, b) => Number(a.toggleAble) - Number(b.toggleAble))
              .map((widget) => (
                <Col span={12} key={widget._id}>
                  <WidgetWrapper
                    title={widget.name}
                    Component={WidgetComponent[widget.kind]}
                    generic={widget.kind === WidgetKind.LAST_ENTRIES}
                  />
                </Col>
              ))}
            <Col span={12}>
              <Flex onClick={() => setOpen(true)} cursor="pointer">
                <WidgetWrapper
                  generic
                  title={t('widget.more')}
                  Component={WidgetCreateWidget}
                />
              </Flex>
            </Col>
          </Row>
        )}
      </Wrapper>

      <AtiraTour />
      <WidgetCustomizeDrawer open={open} onClose={() => setOpen(false)} />
    </Flex>
  );
};
