import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { StepsProps } from 'antd';
import React, { ReactNode, useState } from 'react';

import i18n from '../i18n';
import { AtiraIcon } from './AtiraIcon';
import { Button } from './Button';
import { Flex } from './Flex';

interface AtiraStepperProps extends StepsProps {
  components: ReactNode[];
  items: StepsProps['items'];
  style?: React.CSSProperties;
  fixed?: boolean;
}

const webisteLanguage = i18n.language;

export const AtiraStepper: React.FC<AtiraStepperProps> = (props) => {
  const [currentStep, setCurrentStep] = useState(0);

  const Component = props.components[currentStep]!;

  const prevStep = () => {
    setCurrentStep((x) => --x);
  };

  const nextStep = () => {
    setCurrentStep((x) => ++x);
  };

  return (
    <Flex flex={1} justifyContent="center" gap="l" style={props.style}>
      <Flex width={'100%'}>
        {Component}
        <Flex
          justifyContent="center"
          position="fixed"
          style={{ bottom: '2rem', left: '12rem', right: '1rem' }}
        >
          <Flex justifyContent="space-between" alignItems="center" width="50%">
            <Button
              align="center"
              backgroundColor="lighterPurple"
              onClick={prevStep}
              style={{ visibility: currentStep === 0 ? 'hidden' : 'visible' }}
            >
              <AtiraIcon
                icon={webisteLanguage === 'ar' ? faAngleRight : faAngleLeft}
              />
            </Button>
            <Button
              align="center"
              backgroundColor="lighterPurple"
              onClick={nextStep}
              style={{
                visibility:
                  currentStep === props.components.length - 1
                    ? 'hidden'
                    : 'visible',
              }}
            >
              <AtiraIcon
                icon={webisteLanguage === 'ar' ? faAngleLeft : faAngleRight}
              />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
