import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Drawer, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { Widget } from '../../../../model/widget/Widget';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { widgetSliceSelectors } from '../../../../redux/widget/widget.selector';
import { widgetActions } from '../../../../redux/widget/widget.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';

type WidgetCustomizeDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const WidgetCustomizeDrawer: React.FC<WidgetCustomizeDrawerProps> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const widgets = useAppSelector(widgetSliceSelectors.selectUserWidgets);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onToggleWidget = async (widget: Widget) => {
    try {
      setLoading(true);

      await dispatch(
        widgetActions.EditUserWidget({
          widgetId: widget._id,
          userId,
          active: !widget.active,
        }),
      ).unwrap();
      await dispatch(widgetActions.getUserWidgets({ userId })).unwrap();

      AtiraToast.success(
        widget.active
          ? t('widget.disable.success')
          : t('widget.enable.success'),
      );
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer title={t('widget.customize')} onClose={onClose} open={open}>
      <Text fontSize="l" fontWeight={'bold'}>
        {t('widget.customize.description')}
      </Text>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        width={'100%'}
        marginTop="s"
      >
        {widgets.map((widget) => (
          <Flex key={widget._id} width={'100%'}>
            <Flex
              alignItems="center"
              justifyContent="start"
              gap="s"
              width={'100%'}
            >
              <Flex
                backgroundColor={theme.lightergray}
                width={'80%'}
                borderRadius={'2rem'}
                alignItems="center"
              >
                <Text
                  fontSize="xm"
                  fontWeight={'bold'}
                  style={{ padding: '0.5rem' }}
                >
                  {widget.name}
                </Text>
              </Flex>
              {widget.toggleAble ? (
                <Tooltip title={t('common.disable')}>
                  <Button
                    padding="0.5rem"
                    backgroundColor="transparent"
                    loading={loading}
                    disabled={loading}
                    onClick={() => onToggleWidget(widget)}
                  >
                    <AtiraIcon
                      icon={widget.active ? faMinusCircle : faPlusCircle}
                      color="main"
                      size="3x"
                    />
                  </Button>
                </Tooltip>
              ) : null}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Drawer>
  );
};
