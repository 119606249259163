export function setLangugeAndFont() {
  const storedLang = window.localStorage.getItem('lang') || 'en';
  const storedDir = window.localStorage.getItem('dir') || 'ltr';

  document.documentElement.setAttribute('dir', storedDir);
  document.documentElement.setAttribute('lang', storedLang);

  switch (storedLang) {
    case 'en': {
      document.documentElement.classList.add('atira-english-font');
      break;
    }

    case 'ar': {
      document.documentElement.classList.add('atira-arabic-font');
      break;
    }
  }
}
