import { Checkbox, Drawer } from 'antd';
import type { CheckboxOptionType, GetProp } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraSpaceGreyIcon } from '../../../components/AtiraSpaceGreyIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import i18n, { AppLangs } from '../../../i18n';
import { UpdateUserEntriesTableSettingsDto } from '../../../model/entries-table-settings/dto/UpdateUserEntriesTableSettingsDto';
import { entrySliceSelectors } from '../../../redux/entry/entry.selector';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledCheckbox = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  column-gap: 5rem;
  max-width: 20rem;

  .ant-checkbox .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${(props) => props.theme.black};
    border-color: ${(props) => props.theme.black};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.black} !important;
    border-color: ${(props) => props.theme.black} !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.gray};
  }

  .ant-checkbox + span {
    font-size: 1.5rem;
    width: 5rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -70%);
  }
`;

type EntryTableSettingsModalProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const EntryTableSettingsDrawer: React.FC<
  EntryTableSettingsModalProps
> = ({ open, onClose }) => {
  const [userOptions, setUserOptions] = useState<string[]>([]);
  const [updateColumnsLoading, setUpdateColumnsLoading] = useState(false);
  const drawerPlacement = i18n.language === AppLangs.AR ? 'left' : 'right';

  const { t } = useTranslation(undefined, {});
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );

  const allOptions: CheckboxOptionType[] = (tableSettings?.columns || [])
    .map((c) => ({
      label: t(`common.${c.field}`),
      value: c.field,
      disabled: c.toggleAble === false,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const onCheckBoxChange: GetProp<typeof Checkbox.Group, 'onChange'> = (
    checkedValues,
  ) => {
    setUserOptions(checkedValues as string[]);
  };

  const onSaveColumns = async () => {
    try {
      setUpdateColumnsLoading(true);
      const dto: UpdateUserEntriesTableSettingsDto = {
        tableId: tableSettings?._id!,
        userId,
        columns: tableSettings?.columns.map((col) => ({
          ...col,
          enabled: userOptions.includes(col.field),
        })),
      };

      await dispatch(entryActions.updateUserTableSettings(dto)).unwrap();
      await dispatch(entryActions.getUserTableSettings({ userId })).unwrap();
      AtiraToast.success(t('columns.button.success'));
      onClose();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpdateColumnsLoading(false);
    }
  };

  useEffect(() => {
    const _userOptions = (tableSettings?.columns || [])
      ?.filter(({ enabled }) => enabled)
      .map(({ field }) => field);

    setUserOptions(_userOptions);
    // Do not remove `open`
    // We need to update the userOptions when the modal is opened to keep the state in sync
  }, [tableSettings?.columns, open]);

  return (
    <Drawer
      destroyOnClose
      open={open}
      onClose={onClose}
      width={'30rem'}
      title={t('entries.table.settings.columns.header')}
      zIndex={9999}
      placement={drawerPlacement}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height={'100%'}
      >
        <Flex gap="xl" flexDirection="column">
          <Flex flexDirection="column" gap="s">
            <Text fontSize="l">
              {t('entries.table.settings.columns.title')}
            </Text>
            <Text color="gray" fontSize="m">
              {t('entries.table.settings.columns.description')}
            </Text>
          </Flex>

          <StyledCheckbox
            options={allOptions}
            value={userOptions}
            onChange={onCheckBoxChange}
          />

          <Button
            width="15rem"
            height="2.5rem"
            borderRadius="0.4rem"
            loading={updateColumnsLoading}
            onClick={onSaveColumns}
            title={t('entries.table.settings.columns.button')}
          />
        </Flex>
        <AtiraSpaceGreyIcon />
      </Flex>
    </Drawer>
  );
};
