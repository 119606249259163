import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { UserAxios } from '../../axios/user/user.axios';
import { User } from '../../model/user/User';
import { CodeVerifyDto } from '../../model/user/dto/CodeVerifyDto';
import { ForgotPasswordEmailDto } from '../../model/user/dto/ForgotPasswordEmailDto';
import { ForgotPasswordNewPasswordDto } from '../../model/user/dto/ForgotPasswordNewPasswordDto';
import { ForgotPasswordTokenDto } from '../../model/user/dto/ForgotPasswordTokenDto';
import { LoginDto } from '../../model/user/dto/LoginDto';
import { LoginResponseDto } from '../../model/user/dto/LoginResponseDto';
import { RefreshTokenDto } from '../../model/user/dto/RefrehTokenDto';
import { SignupDto } from '../../model/user/dto/SignupDto';
import { SignupSuccessDto } from '../../model/user/dto/SignupSuccessDto';
import { UpdateUserDto } from '../../model/user/dto/UpdateUserDto';
import { AtiraThunk } from '../AtiraThunk';

interface UserReducer {
  loggedInUserId: string | undefined;
  loggedInUser: User | undefined;
}

const initialState = Object.freeze<UserReducer>({
  loggedInUserId: undefined,
  loggedInUser: undefined,
});

const createLoginWithGoogleLink = AtiraThunk<string, void>(
  `/auth/google-create-link`,
  () => UserAxios.createLoginWithGoogleLink(),
);

const loginWithGoogle = AtiraThunk<SignupSuccessDto, { code: string }>(
  '/auth/google',
  (dto: { code: string }) => UserAxios.loginWithGoogle(dto),
);

const getLoggedInUser = AtiraThunk<User, { userId: string }>(
  '/user/self',
  ({ userId }) => UserAxios.getLoggedInUser(userId),
);

const logout = AtiraThunk<void, void>(`/logout`, () => {
  localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY!);
});

const refreshToken = AtiraThunk<RefreshTokenDto, void>(
  `/auth/refersh-token`,
  () => UserAxios.refreshToken(),
);

const signup = AtiraThunk<void, SignupDto>(`/user/signup`, (dto) =>
  UserAxios.signup(dto),
);

// After signup
const verifyUserAccount = AtiraThunk<SignupSuccessDto, CodeVerifyDto>(
  `/user/code-verify`,
  (dto) => UserAxios.verifyUserAccount(dto),
);

const login = AtiraThunk<LoginResponseDto, LoginDto>(`/user/login`, (dto) =>
  UserAxios.login(dto),
);

const forgotPasswordEmail = AtiraThunk<void | string, ForgotPasswordEmailDto>(
  '/user/reset-password',
  (dto) => UserAxios.forgotPasswordEmail(dto),
);

const forgotPasswordToken = AtiraThunk<void, ForgotPasswordTokenDto>(
  '/user/reset-password',
  (dto) => UserAxios.forgotPasswordToken(dto),
);

const forgotPasswordNewPassword = AtiraThunk<
  void,
  ForgotPasswordNewPasswordDto
>('/user/reset-password', (dto) => UserAxios.forgotPasswordNewPassword(dto));

const updateUserInfo = AtiraThunk<void, UpdateUserDto>(
  '/user/:id/update',
  (dto) => UserAxios.updateUserInfo(dto),
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: ({ addCase, addMatcher }) => {
    addCase(
      refreshToken.fulfilled,
      (state, action: PayloadAction<RefreshTokenDto>) => {
        localStorage.setItem(
          process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY!,
          action.payload.token,
        );
      },
    );

    addCase(getLoggedInUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.loggedInUser = action.payload;
      }
    });

    addCase(logout.fulfilled, (state: UserReducer) => {
      state.loggedInUser = undefined;
      state.loggedInUserId = undefined;
      localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY!);
      localStorage.removeItem('persist:root');
    });

    addMatcher(
      isAnyOf(
        loginWithGoogle.fulfilled,
        verifyUserAccount.fulfilled,
        login.fulfilled,
      ),
      (
        state: UserReducer,
        action: PayloadAction<SignupSuccessDto | LoginResponseDto>,
      ) => {
        localStorage.setItem(
          process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY!,
          action.payload.token,
        );

        localStorage.setItem(process.env.REACT_APP_TOUR!, 'true');

        state.loggedInUser = action.payload.user;
        state.loggedInUserId = action.payload.user._id;
      },
    );
  },
});

export const userActions = {
  loginWithGoogle,
  getLoggedInUser,
  logout,
  refreshToken,
  createLoginWithGoogleLink,
  signup,
  verifyUserAccount,
  login,
  forgotPasswordEmail,
  forgotPasswordToken,
  forgotPasswordNewPassword,
  updateUserInfo,
};

export default userSlice.reducer;
