import { IconProp } from '@fortawesome/fontawesome-svg-core';
import merge from 'lodash/merge';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import i18n from '../i18n';
import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Text } from './Text';

const websiteLanguage = i18n.language;

const StyledInput = styled.input`
  width: 100%;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 1px ${(props) => props.theme.transparent};
  outline: none !important;

  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.main};
  }
`;

enum TitleAlignment {
  start = 'start',
  center = 'center',
  end = 'end',
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  id?: string;
  valid?: boolean;
  width?: string;
  height?: string;
  border?: string;
  borderRadius?: string;
  placeholder?: string;
  padding?: string;
  margin?: string;
  icon?: IconProp;
  errorMessage?: string;
  color?: string;
  titleAlign?: keyof typeof TitleAlignment;
}

export const Input: React.FC<InputProps> = (props) => {
  const { theme } = useTheme();

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      {props.title ? (
        <label
          htmlFor={props.id}
          style={{
            color: theme.black,
            cursor: 'pointer',
            textAlign: props.titleAlign,
            width: 'fit-content',
            alignSelf: props.titleAlign || 'flex-start',
          }}
        >
          {props.title}
          {props.required ? <span style={{ color: theme.red }}>*</span> : null}
        </label>
      ) : null}

      <Flex
        position="relative"
        flexDirection="column"
        gap="s"
        width="100%"
        style={{ paddingLeft: props.icon ? '4rem' : '0' }}
      >
        {props.icon ? (
          <Flex
            style={{
              position: 'absolute',
              left: websiteLanguage === 'ar' ? '0.5rem' : 'auto',
              right: websiteLanguage === 'ar' ? '0.5rem' : 'auto',
            }}
          >
            <AtiraIcon icon={props.icon} />
          </Flex>
        ) : null}
        <StyledInput
          style={merge(
            props.style,
            props.disabled ? { opacity: '0.2' } : {},
            props.valid === false
              ? { border: '2px solid red', boxShadow: 'unset' }
              : {},
            {
              width: '100%',
              height: props.height,
              border: props.border,
              borderRadius: props.borderRadius,
              placeholder: props.placeholder,
              padding: props.padding || '0 0.5rem',
              margin: props.margin,
            },
          )}
          {...props}
        />
        {props.errorMessage && props.valid === false ? (
          <Text color="red" fontSize="s">
            {props.errorMessage}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};
