import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import React, {
  ButtonHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { Spacing } from '../../theme/Spacing';
import { Theme } from '../../theme/Theme';
import { AtiraToast } from '../../utils/AtiraToast';
import { AtiraIcon } from '../AtiraIcon';
import { Button } from '../Button';
import { Text } from '../Text';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  backgroundColor?: string;
  padding?: string;
  fontSize?: keyof typeof Spacing;
  borderRadius?: string;
  border?: string;
  children?: React.ReactNode;
  margin?: string;
  width?: string;
  height?: string;
  loading?: boolean;
}

const StyledButton: React.FC<ButtonProps> = styled(Button)`
  background-color: ${(props) => props.backgroundColor || 'purple'};
  color: ${(props) => props.color || 'white'};
  padding: ${(props) => props.padding || '0.7rem 1rem'};
  border-radius: ${(props) => props.borderRadius || '1.5rem'};
  border: ${(props) => props.border || 'none'};
  margin: auto;
  font-size: ${(props) => props.fontSize || '1rem'};
  cursor: pointer;
`;

interface GoogleButtonProps {
  width?: string;
  height?: string;
  backgroundColor?: keyof typeof Theme;
  padding?: string;
  borderRadius?: string;
  border?: string;
  color?: keyof typeof Theme;
}

export const GoogleButton: React.FC<GoogleButtonProps> = ({
  backgroundColor,
  border,
  color,
  width,
  height,
  padding,
  borderRadius,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );
  const [isLoading, setIsLoading] = useState(false);

  const googleAuth = async () => {
    setIsLoading(true);
    try {
      const googleAuthUrl = await dispatch(
        userActions.createLoginWithGoogleLink(),
      ).unwrap();

      window.open(googleAuthUrl, '_self');
    } catch (error: any) {
      console.log(error);
      AtiraToast.error(
        'Something went wrong, please try again later or contact support',
      );
      setIsLoading(false);
    }
  };

  const verifyAndLogUser = useCallback(
    async (code: string) => {
      try {
        const { user } = await dispatch(
          userActions.loginWithGoogle({ code }),
        ).unwrap();

        if (user) {
          setIsLoading(false);
          navigate('/dashboard', { replace: true });
        }
      } catch (error) {
        console.log(error);
        AtiraToast.error(
          'Something went wrong, please try again later or contact support',
        );
        setIsLoading(false);
      }
    },
    [navigate, dispatch],
  );

  useEffect(() => {
    const verificationCode = searchParams.get('code');
    if (verificationCode && !loggedInUserId) {
      setIsLoading(true);
      searchParams.set('code', '');
      verifyAndLogUser(verificationCode);
    }
  }, [searchParams, verifyAndLogUser, loggedInUserId]);

  return (
    <StyledButton
      loading={isLoading}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      border={border}
      onClick={googleAuth}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        height,
        width,
      }}
    >
      <AtiraIcon icon={faGoogle} size="2x" color="main" />
      <Text fontSize="m" color={color}>
        {t('login_signup.google')}
      </Text>
    </StyledButton>
  );
};
