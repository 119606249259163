import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { useAppSelector } from '../../../redux/store';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledFlex = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

interface ShareFormProps {
  open: boolean;
  onClose: VoidFunction;
}

export const ShareFormModal: React.FC<ShareFormProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const formLink = currentForm
    ? `${process.env.REACT_APP_API_URL}/form/${currentForm._id}/generate`
    : null;

  const formIframe = currentForm
    ? `<iframe width="600" height="400" style="border: none;" src='${formLink}'></iframe>`
    : null;

  const onCopy = (str: string) => {
    navigator.clipboard.writeText(str);
    AtiraToast.success(t('shorten_url.copy.success'));
  };

  if (!currentForm) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Share Form: ${currentForm.title}`}
      titleProps={{
        style: { color: theme.gray, textAlign: 'left', fontSize: '1.25rem' },
      }}
    >
      <Flex
        width={'100%'}
        gap="l"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        <hr />

        <StyledFlex>
          <Text color="gray">Direct Link:</Text>

          <Flex justifyContent="space-between" alignItems="center">
            <Text
              color="darkerPurple"
              wordBreak="break-word"
              style={{
                background: theme.lightgray,
                padding: '0.5rem 0.2rem',
                borderRadius: '0.3rem',
                width: '25rem',
                margin: 0,
              }}
              fontSize="s"
              fontWeight="bold"
            >
              {formLink}
            </Text>
            <Tooltip title={t('common.copy')}>
              <Button
                onClick={() => onCopy(formLink || '')}
                backgroundColor="transparent"
                margin="0"
              >
                <AtiraIcon color="gray" size="2x" icon={faCopy} />
              </Button>
            </Tooltip>
          </Flex>
        </StyledFlex>

        <StyledFlex>
          <Text color="gray">Iframe Embed</Text>

          <Flex justifyContent="space-between" alignItems="center">
            <code
              style={{
                display: 'inline-block',
                overflow: 'auto',
                background: theme.lightgray,
                padding: '0.5rem 0.2rem',
                borderRadius: '0.3rem',
                fontSize: '0.75rem',
                width: '25rem',
                fontWeight: 'bold',
              }}
            >
              {formIframe}
            </code>

            <Tooltip title={t('common.copy')}>
              <Button
                onClick={() => onCopy(formIframe!)}
                backgroundColor="transparent"
                margin="0"
              >
                <AtiraIcon color="gray" size="2x" icon={faCopy} />
              </Button>
            </Tooltip>
          </Flex>
        </StyledFlex>
      </Flex>
    </Modal>
  );
};
