import { Modal as AntdModal } from 'antd';

import { Flex } from '../Flex';
import { ParagraphProps, Text } from '../Text';

interface ModalProps {
  children: React.ReactNode;
  title?: string;
  titleProps?: ParagraphProps;
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
  open: boolean;
  onClose?: VoidFunction;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  content,
  overlay,
  title,
  titleProps = {},
  open,
}) => {
  return (
    <AntdModal
      visible={open}
      onCancel={onClose}
      footer={null}
      title={
        <Text fontSize="xl" align="center" fontWeight={'bold'} {...titleProps}>
          {title}
        </Text>
      }
      closeIcon
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        {children}
      </Flex>
    </AntdModal>
  );
};
