import merge from 'lodash/merge';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { useTheme } from '../ThemeContext';
import { Flex } from './Flex';

const StyledTextarea = styled.textarea`
  min-height: 4rem;
  max-width: 100%;
  min-width: 100%;
  min-height: 4rem;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 1px ${(props) => props.theme.transparent};
  outline: none;

  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.main};
  }
`;

interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  id?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  rows?: number;
  border?: string;
  borderRadius?: string;
  placeholder?: string;
  padding?: string;
  margin?: string;
  color?: string;
}

export const Textarea: React.FC<TextareaProps> = (props) => {
  const { theme } = useTheme();

  return (
    <Flex flexDirection="column" gap="s" width={props.width || '100%'}>
      {props.title ? (
        <label
          htmlFor={props.id}
          style={{
            color: theme.black,
            cursor: 'pointer',
          }}
        >
          {props.title}
          {props.required ? <span style={{ color: theme.red }}>*</span> : null}
        </label>
      ) : null}
      <Flex position="relative" width="100%">
        <StyledTextarea
          style={merge(props.style, props.disabled ? { opacity: '0.2' } : {}, {
            border: props.border,
            borderRadius: props.borderRadius,
            placeholder: props.placeholder,
            padding: props.padding || '0.5rem',
            margin: props.margin,
            maxHeight: props.maxHeight,
          })}
          rows={props.rows}
          {...props}
        />
      </Flex>
    </Flex>
  );
};
