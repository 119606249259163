import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Flex } from '../../../../components/Flex';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.darkSub};
  border-radius: 1rem;
  overflow-y: scroll;
  width: 100%;
  height: 14rem;
  padding: 1rem;
`;

export const WidgetCreateWidget: React.FC = () => {
  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" width={'100%'}>
        <AtiraIcon icon={faPlus} size="6x" color="darkerSub" />
      </Flex>
    </Container>
  );
};
