import { Layout as LayoutComponent } from 'antd';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useTheme } from '../ThemeContext';
import { Flex } from '../components/Flex';
import { SideMenu } from '../components/SideMenu';
import { appSliceSelectors } from '../redux/app/app.selector';
import { appActions } from '../redux/app/app.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { Header } from './shared/Header';

export const Layout: React.FC = () => {
  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  const storedValue = useAppSelector(appSliceSelectors.selectSideMenuCollapsed);

  const [collapsed, setCollapsed] = useState(Boolean(storedValue));

  const onCollapse = (newCollapsed: boolean) => {
    setCollapsed(newCollapsed);
    dispatch(appActions.setSideMenuCollapsed(newCollapsed));
  };

  return (
    <LayoutComponent style={{ width: '100%', height: '100%' }}>
      <Header />
      <LayoutComponent.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{
          overflow: 'auto',
          height: '100vh',
          backgroundColor: theme.main,
        }}
        width={'15.5rem'}
      >
        <SideMenu collapsed={collapsed} />
      </LayoutComponent.Sider>

      <Flex
        justifyContent="space-between"
        width={'100%'}
        height={'calc(100% - 3.5rem)'}
        flexDirection="column"
        overflowY="scroll"
        style={{ marginTop: '3.5rem' }}
        backgroundColor={theme.sub}
      >
        <Outlet />
      </Flex>
    </LayoutComponent>
  );
};
