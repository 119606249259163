import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Tooltip } from 'antd';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';

const FormInput = styled(Input)`
  width: 100%;
  height: 3rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 2px solid black;
  font-size: 1.2rem;
  align-self: center;
  opacity: 1 !important;
`;

export const FormCreateStepBasicInfo: React.FC = () => {
  const { t } = useTranslation();

  const { theme } = useTheme();

  const { control, watch } = useFormContext<CreateFormDto>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'domains',
  });

  const title = watch('title');

  return (
    <Flex
      height="100%"
      width={'50%'}
      flexDirection="column"
      alignItems="center"
      gap="m"
    >
      <Flex width={'100%'} flexDirection="column" alignItems="center" gap="m">
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <FormInput
              placeholder={t('forms.create.step.1.input.form_name.placeholder')}
              title={t('forms.create.step.1.input.form_name.label')}
              onChange={onChange}
              value={value}
              id="title"
              required
            />
          )}
        />

        <Flex gap="m" justifyContent="center" width={'100%'}>
          <Controller
            control={control}
            name={`domains.${0}.content`}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <FormInput
                title={t('forms.create.step.1.input.domain.title')}
                placeholder={t('forms.create.step.1.input.domain.placeholder')}
                value={value}
                onChange={onChange}
                required
              />
            )}
          />
          <Tooltip title={t('forms.create.step.1.new_domain_field.tooltip')}>
            <Button
              height="3rem"
              padding="0.2rem"
              margin="auto 0 0 0"
              backgroundColor="transparent"
              color="main"
              iconWidth="2x"
              onClick={() => append({ content: '' })}
              disabled={fields.length > 2}
              icon={faPlus}
            />
          </Tooltip>
        </Flex>

        {fields.slice(1).map((field, index) => (
          <Flex gap="m" justifyContent="center" width={'100%'} key={field.id}>
            <Controller
              control={control}
              name={`domains.${index + 1}.content`}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  type="text"
                  placeholder={t(
                    'forms.create.step.1.input.domain.placeholder',
                  )}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Tooltip
              title={t('forms.create.step.1.delete_domain_field.tooltip')}
            >
              <Button
                height="3rem"
                padding="0.2rem"
                margin="auto 0 0 0"
                backgroundColor="transparent"
                color="main"
                iconWidth="2x"
                onClick={() => remove(index + 1)}
                icon={faTrash}
              />
            </Tooltip>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
