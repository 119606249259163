/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Flex } from '../../../../components/Flex';
import { GenericTokenDto } from '../../../../model/user/dto/GenericTokenDto';
import { SignupDto } from '../../../../model/user/dto/SignupDto';
import { SignupEmail } from './SignupEmail';
import { SignupToken } from './SignupToken';

enum SIGNUP_STEPS {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
}

export const Signup: React.FC = () => {
  const [step, setStep] = useState<SIGNUP_STEPS>(SIGNUP_STEPS.EMAIL);
  const formMethods = useForm<SignupDto & GenericTokenDto>({
    defaultValues: { email: '', token: '' },
  });

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <FormProvider {...formMethods}>
        {step === SIGNUP_STEPS.EMAIL ? (
          <SignupEmail updateStep={() => setStep(SIGNUP_STEPS.TOKEN)} />
        ) : (
          <SignupToken />
        )}
      </FormProvider>
    </Flex>
  );
};
