import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { ThemeType } from '../../theme/Theme';

const documentDirection = window.localStorage.getItem('dir');

interface ArrowProps {
  backgroundColor: keyof ThemeType;
}
const TemplateButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.black};
  font-size: 1.2rem;
  width: 100%;
  border-radius: 0;
  text-align: ${documentDirection === 'ltr' ? 'left' : 'right'};
`;

const Arrow = styled.div<ArrowProps>`
  height: 2rem;
  width: 2rem;
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  background-color: ${(props) => props.theme[props.backgroundColor]};
  transform: rotate(${documentDirection === 'rtl' ? '180deg' : '0'});
`;

enum TemplateTypes {
  ALL = 'all',
  BUSINESS = 'business',
  MEDICAL = 'medical',
  REALESTATE = 'real-estate',
  SCHOOL = 'school',
  PERSONAL = 'personal',
  LEAD = 'lead',
}

export const TemplatesSideMenu: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [templateType, setTemplateType] = useState<TemplateTypes>(
    TemplateTypes.ALL,
  );

  return (
    <Flex borderRight={`2px solid ${theme.black}`} flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton onClick={() => setTemplateType(TemplateTypes.ALL)}>
          {t('common.all')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.ALL ? 'black' : 'transparent'
          }
        />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton onClick={() => setTemplateType(TemplateTypes.BUSINESS)}>
          {t('templates.side_menu.business')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.BUSINESS ? 'black' : 'transparent'
          }
        />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton onClick={() => setTemplateType(TemplateTypes.MEDICAL)}>
          {t('templates.side_menu.medical')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.MEDICAL ? 'black' : 'transparent'
          }
        />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton
          onClick={() => setTemplateType(TemplateTypes.REALESTATE)}
        >
          {t('templates.side_menu.real_estate')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.REALESTATE ? 'black' : 'transparent'
          }
        />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton onClick={() => setTemplateType(TemplateTypes.SCHOOL)}>
          {t('templates.side_menu.school')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.SCHOOL ? 'black' : 'transparent'
          }
        />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton onClick={() => setTemplateType(TemplateTypes.PERSONAL)}>
          {t('templates.side_menu.personal')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.PERSONAL ? 'black' : 'transparent'
          }
        />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <TemplateButton onClick={() => setTemplateType(TemplateTypes.LEAD)}>
          {t('templates.side_menu.lead_generation')}
        </TemplateButton>
        <Arrow
          backgroundColor={
            templateType === TemplateTypes.LEAD ? 'black' : 'transparent'
          }
        />
      </Flex>
    </Flex>
  );
};
