import { last } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { Entry } from '../../model/entry/Entry';
import { entryActions } from '../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { EntryDetailsModal } from './components/EntryDetailsModal';

const OBJECT_ID_REGEX = /^[0-9a-fA-F]{24}$/;

export const EntryViewingModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const entryId = last(pathname.split('/'));

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const [entry, setEntry] = useState<Entry | null>(null);

  const onClose = useCallback(() => {
    navigate('/entries');
  }, [navigate]);

  useEffect(() => {
    const fetchEntry = async () => {
      try {
        if (entryId) {
          if (!OBJECT_ID_REGEX.test(entryId)) {
            // Not a valid object_id;
            onClose();
          }

          const entry = await dispatch(
            entryActions.getEntryById({ entryId, userId }),
          ).unwrap();

          setEntry(entry);
        }
      } catch (e: any) {
        onClose();
      }
    };

    fetchEntry();
  }, [dispatch, entryId, onClose, userId]);

  if (!entry) {
    return <SpinnerFullScreen />;
  }

  return (
    <EntryDetailsModal key={entry._id} isOpen onClose={onClose} entry={entry} />
  );
};
