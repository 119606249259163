import { Image, ImageProps } from 'antd';
import React from 'react';

export interface AtiraImageProps extends ImageProps {
  width?: string;
  height?: string;
  src: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const AtiraImage: React.FC<AtiraImageProps> = ({
  width = '100%',
  height,
  src,
  onClick,
  style,
  preview = false,
  ...props
}) => {
  return (
    <Image
      referrerPolicy="no-referrer"
      alt="atira_image"
      src={src}
      width={width}
      height={height}
      onClick={onClick}
      style={style}
      preview={preview}
      {...props}
    />
  );
};
