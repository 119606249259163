import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const templateState = (state: RootState) => state.template;

const selectAllTemplates = createSelector(
  templateState,
  (state) => state.templates,
);

export const templateSliceSelectors = { selectAllTemplates };
