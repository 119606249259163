import { Drawer } from 'antd';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { UpdateEntryDto } from '../../../model/entry/dto/UpdateEntryDto';
import { CreateFormDtoInputNames } from '../../../model/form/types/CreateFormDtoInputNames.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledInput = styled(Input)`
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 2px solid ${(props) => props.theme.main};
`;

const StyledTextarea = styled(Textarea)`
  border-radius: 0.3rem;
  border: 2px solid ${(props) => props.theme.main};
  max-height: 15rem;
`;

const StyledButton = styled(Button)`
  width: 7rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  font-size: 1.3rem;
`;

const validateNameAndEmail = (
  value: string,
  inputName: CreateFormDtoInputNames,
  t: TFunction,
) => {
  if (
    inputName === CreateFormDtoInputNames.NAME &&
    (!value.trim() || value.trim().length < 3)
  ) {
    return t('entries.update.error.short_name');
  }
  if (
    inputName === CreateFormDtoInputNames.EMAIL &&
    (!value.trim() ||
      !/[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/.test(value.trim()))
  ) {
    return t('entries.update.error.email_validate');
  }
  return true;
};

interface UpdateEntryDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
}

export const EntryUpdateDrawer: React.FC<UpdateEntryDrawerProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const [updateEntryLodaing, setUpdateEntryLoading] = useState(false);

  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const dispatch = useAppDispatch();

  const { control, getValues, reset, handleSubmit, register } =
    useForm<UpdateEntryDto>();

  const onUpdateEntry = async () => {
    try {
      setUpdateEntryLoading(true);

      const dto = getValues();

      const dtoToUpdate = {} as UpdateEntryDto;

      if (dto.description !== entry?.description) {
        dtoToUpdate.description = dto.description;
      }

      const fieldsToUpdate = Object.entries(
        dto.fields as Record<CreateFormDtoInputNames, string>,
      ).reduce(
        (acc, [key, value]) => {
          if (
            entry?.fields[
              key as keyof Record<CreateFormDtoInputNames, string>
            ] !== value
          ) {
            acc[key as keyof Record<CreateFormDtoInputNames, string>] = value;
          }
          return acc;
        },
        {} as Record<CreateFormDtoInputNames, string>,
      );

      if (Object.keys(fieldsToUpdate).length > 0) {
        dtoToUpdate.fields = fieldsToUpdate;
      }

      if (Object.keys(dtoToUpdate).length) {
        await dispatch(entryActions.updateEntryById(dto)).unwrap();
        await dispatch(
          entryActions.getEntryById({ entryId: entry?._id!, userId: userId }),
        ).unwrap();
      }
      onClose();

      AtiraToast.success(t('entries.update.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.error(e);
    } finally {
      setUpdateEntryLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        description: entry?.description || '',
        entryId: entry?._id || '',
        userId: userId,
        fields: entry?.fields || {},
      });
    }
  }, [userId, entry, isOpen]);

  return (
    <Drawer
      title={`${t('entries.modal.update.title')} - ${entry?.fields.name || entry?.fields.email}`}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flexDirection="column" justifyContent="center" gap="m">
        {entry?.parentForm?.inputs?.map((input) => {
          return (
            <Controller
              key={input._id}
              name={`fields.${input.name}`}
              control={control}
              rules={{
                required: input.required,
                validate: (value: string) =>
                  validateNameAndEmail(value, input.name, t),
              }}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <StyledInput
                  value={value}
                  onChange={onChange}
                  title={t(`common.${input.name}`)}
                  id={input._id}
                  type={input.type}
                  required={input.required}
                  valid={!(errors.fields?.[input.name] as FieldError)?.message}
                  errorMessage={
                    (errors.fields?.[input.name] as FieldError)?.message
                  }
                />
              )}
            />
          );
        })}
        <Controller
          name={'description'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Flex flexDirection="column" gap="s">
              <StyledTextarea
                value={value}
                onChange={onChange}
                id={'description'}
                rows={3}
                title={t('common.description')}
              />
            </Flex>
          )}
        />

        <StyledButton
          onClick={handleSubmit(onUpdateEntry)}
          loading={updateEntryLodaing}
          title={t('common.update')}
        />
      </Flex>
    </Drawer>
  );
};
