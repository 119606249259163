import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../ThemeContext';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { useAppDispatch } from '../../redux/store';
import { templateActions } from '../../redux/template/template.slice';
import { TemplatesCards } from './TemplateCards';
import { TemplatesSideMenu } from './TemplatesSideMenu';

export const Templates: React.FC = () => {
  const { t } = useTranslation();

  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        await dispatch(templateActions.getTemplates()).unwrap();
      } catch (error) {
        console.log(error);
      }
    };
    fetchTemplates();
  }, [dispatch]);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="l"
      flexWrap="wrap"
    >
      <Flex
        backgroundColor={theme.main}
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        gap="l"
        padding="l"
        height={'13rem'}
      >
        <Text color="white" align="center" fontSize="xxl">
          {t('templates.hero.title')}
        </Text>
        <Text color="white" align="center" fontSize="l">
          {t('templates.hero.paragraph')}
        </Text>
      </Flex>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        gap="s"
      >
        <TemplatesSideMenu />
        <TemplatesCards />
      </Flex>
    </Flex>
  );
};
