import { AtiraRoute } from '../AtiraRoute';
import { Dashboard } from '../dashboard/Dashboard';
import { EntriesRoute } from '../entries/EntriesRoute';
import { EntryViewingModal } from '../entries/EntryViewingModal';
import { FormCreate } from '../form/FormCreate';
import { UserForms } from '../form/UserForms';
import { Kanban } from '../kanban/Kanban';

export const PrivateBasicRouter = [
  {
    path: '/',
    element: <AtiraRoute redirect="dashboard" />,
  },
  {
    path: '/dashboard',
    element: <AtiraRoute component={<Dashboard />} />,
  },
  {
    path: '/forms',
    element: <AtiraRoute component={<UserForms />} />,
  },
  {
    path: '/entries',
    element: <AtiraRoute component={<EntriesRoute />} />,
  },
  {
    path: '/entries/view/:id',
    element: <AtiraRoute component={<EntryViewingModal />} />,
  },
  {
    path: '/deals',
    element: <AtiraRoute component={<Kanban />} />,
  },
  {
    path: '/forms/create',
    element: <AtiraRoute component={<FormCreate />} />,
  },
];
