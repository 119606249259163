import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { Spacing } from '../theme/Spacing';

const Container = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.main};
  z-index: 100;
  width: 100%;
  padding: 0 ${Spacing.l};
  gap: ${Spacing.m};
`;

const StartButton = styled(Button)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  background-color: ${(props) => props.theme.white};
  font-size: 0.75rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.black};
  }
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const PublicHeader: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSignUp = () => {
    navigate('/register', { state: { page: 'signup' } });
  };

  const onLogin = () => {
    navigate('/register', { state: { page: 'login' } });
  };

  return (
    <Container>
      <Flex maxWidth="15rem" flex={1}>
        <AtiraImage src={Images.AtiraSpaceLogo} width="100%" />
      </Flex>

      <Flex gap="m" alignItems="center">
        <StartButton onClick={onLogin}>{t('common.sign_in')}</StartButton>

        <StartButton onClick={onSignUp}>{t('common.signup')}</StartButton>
      </Flex>
    </Container>
  );
};
