import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Images } from '../../assets';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import i18n from '../../i18n';
import { Spacing } from '../../theme/Spacing';
import {
  GetStartedComponents,
  useGetStartedContext,
} from '../loginSignup/GetStartedContext';

const websiteLanguage = i18n.language;

const HeroText = styled(Text)`
  text-align: center;
  @media (min-width: 992px) {
    text-align: start;
  }
`;

const HeroSection = styled(Flex)`
  gap: ${Spacing.xxl};
  position: relative;
  z-index: 1;
  min-height: 90vh;
  padding: ${Spacing.m} ${Spacing.s};
  background-image: url(${Images.FeaturesBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  @media (min-width: 768px) {
    padding: ${Spacing.l} ${Spacing.xxxl};
  }
`;

const HeroBackgroundBlur = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(${Images.HeroBackground});
  background-image: none;
  z-index: -1;
`;

const HeroPart = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  @media (min-width: 992px) {
    width: 47%;
  }
`;

const HeroLeftPart = styled(HeroPart)`
  @media (min-width: 992px) {
    padding-left: ${Spacing.l};
  }
`;

const HeroC2AWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: ${Spacing.m};
  @media (min-width: 992px) {
    justify-content: flex-start;
  }
`;

const CallToAction = styled(Button)`
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.darkPink};
  border: 1px solid ${(props) => props.theme.darkPink};
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.lightMain};
    border: 1px solid ${(props) => props.theme.lightMain};
  }
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const StyledC2ALink = styled(Link)`
  color: ${(props) => props.theme.black};
  background-color: ${(props) => props.theme.transparent};
  border: 1px solid ${(props) => props.theme.black};
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.5rem;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: ${(props) => props.theme.black};
    color: ${(props) => props.theme.white};
  }
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const HeroImageWrapper = styled(Flex)`
  border-radius: 50%;
  max-width: 20rem;
  padding: ${Spacing.m};
  background-color: ${(props) => props.theme.darkPink};
  position: relative;
  margin-left: ${websiteLanguage === 'ar' ? '0' : 'auto'};
  margin-right: ${websiteLanguage === 'ar' ? 'auto' : '0'};
  @media (min-width: 768px) {
    padding: 2.5rem;
    max-width: 30rem;
  }
`;

const HeroCircleAnimation = keyframes`
  from {
    transform: translateY(-1rem);
  } to {
    transform: translateY(1rem);
  }
`;

const HeroImageLeftCircle = styled(Flex)`
  height: 4rem;
  width: 4rem;
  position: absolute;
  bottom: 5rem;
  left: 0;
  border-radius: 50%;
  background-size: 20rem;
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 1.5s;
  @media (min-width: 768px) {
    height: 6rem;
    width: 6rem;
  }
`;

const HeroImageRightCircle = styled(Flex)`
  height: 5rem;
  width: 5rem;
  background-color: ${(props) => props.theme.darkPink};
  position: absolute;
  bottom: 5rem;
  right: -2rem;
  border-radius: 50%;
  padding: ${Spacing.m};
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  animation: ${HeroCircleAnimation} infinite ease-in-out alternate 2s;
  @media (min-width: 768px) {
    height: 8rem;
    width: 8rem;
  }
`;

const Section = styled(Flex)`
  flex: 1;
  padding: ${Spacing.l} ${Spacing.xxxl};
  overflow: hidden;
  flex-wrap: wrap;
`;

const ResponsiveFlex = styled(Flex)`
  width: 100%;
  @media (min-width: 992px) {
    width: 47%;
  }
`;

export const Features = () => {
  const { t } = useTranslation();
  const { onChangePage } = useGetStartedContext();

  const navigate = useNavigate();

  const onSignUp = () => {
    navigate('/register');
    onChangePage(GetStartedComponents.SIGNUP);
  };

  return (
    <Flex flexDirection="column">
      <HeroSection>
        <Flex alignItems="center" justifyContent="center" marginTop="l">
          <HeroBackgroundBlur />

          <HeroLeftPart>
            <Flex gap="s" flexDirection="column">
              <HeroText color="darkPink" fontWeight="bolder" fontSize="xxxl">
                {t('features.hero.first_title')}
              </HeroText>
              <HeroText fontSize="xl" fontWeight={'bold'}>
                {t('features.hero.second_title')}
              </HeroText>
              <HeroText fontSize="l" style={{ lineHeight: '2.5rem' }}>
                {t('features.hero.paragraph')}
              </HeroText>
              <HeroC2AWrapper>
                <CallToAction onClick={onSignUp}>
                  {t('features.hero.main_c2a')}
                </CallToAction>
                <StyledC2ALink to={'/'}>
                  {t('features.hero.sub_c2a')}
                </StyledC2ALink>
              </HeroC2AWrapper>
            </Flex>
          </HeroLeftPart>

          <HeroPart>
            <HeroImageWrapper>
              <Flex
                width="100%"
                height="100%"
                borderRadius="50%"
                overflow="hidden"
              >
                <AtiraImage src={Images.HeroPhoto} width="100%" height="100%" />
              </Flex>

              <HeroImageLeftCircle>
                <AtiraImage src={Images.FeaturesFirstCircle} />
              </HeroImageLeftCircle>

              <HeroImageRightCircle>
                <AtiraImage src={Images.FeaturesSecondCircle} />
              </HeroImageRightCircle>
            </HeroImageWrapper>
          </HeroPart>
        </Flex>
      </HeroSection>

      <Section>
        <ResponsiveFlex>
          <AtiraImage src={Images.FeaturesFirstSection} />
        </ResponsiveFlex>
        <ResponsiveFlex />
      </Section>

      <Section>
        <ResponsiveFlex />
        <ResponsiveFlex>
          <AtiraImage src={Images.FeaturesSecondSection} />
        </ResponsiveFlex>
      </Section>
    </Flex>
  );
};
