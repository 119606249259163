import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { KanbanAxios } from '../../axios/kanban/kanban.axios';
import { Kanban } from '../../model/kanban/Kanban';
import { KanbanCard } from '../../model/kanban/KanbanCard';
import { CreateKanbanCardDto } from '../../model/kanban/dto/CreateKanbanCardDto';
import { GetKanbanCardsByKanbanColumnIdDto } from '../../model/kanban/dto/GetKanbanCardsByKanbanColumnIdDto';
import { GetUserDefaultKanbanDto } from '../../model/kanban/dto/GetUserDefaultKanbanDto';
import { UpdateKanbanCardDto } from '../../model/kanban/dto/UpdateKanbanCardDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';
import { KanbanCardReduxEntry } from './kanbanCard.reduxy-entry';

interface KanbanReducer {
  kanban: Kanban | undefined;
  kanbanLoading: boolean;
}

const initialState = Object.freeze<KanbanReducer>({
  kanban: undefined,
  kanbanLoading: false,
});

/** Cards by ColumnId
 * A column and its cards
 * Used to get all columns, and if populated, each column would have its child cards
 * TODO figure out if we need this one at all or not
 */
const KanbanCardAdapter = createEntityAdapter<KanbanCardReduxEntry>({});
export const kanbanCardAdapterSelectors = KanbanCardAdapter.getSelectors();

const getKanbanCardsByKanbanColumnId = AtiraThunk<
  PageResponseDto<KanbanCard[]>,
  GetKanbanCardsByKanbanColumnIdDto
>(`/kanban/column/:id`, (dto) =>
  KanbanAxios.getKanbanCardsByKanbanColumnId(dto),
);

const createKanbanCard = AtiraThunk<void, CreateKanbanCardDto>(
  `/kanban/card/create`,
  (dto) => KanbanAxios.createKanbanCard(dto),
);

const getUserDefaultKanban = AtiraThunk<Kanban, GetUserDefaultKanbanDto>(
  '/kanban/default',
  (dto) => KanbanAxios.getUserDefaultKanban(dto),
);

// TODO This call is an exception as in it returns something
// let's do it this way till we figure out a proper way to sync the kanban
// If we return the value in the same patch call, then we don't need another get call to get the updated kanban
const updateKanbanCard = AtiraThunk<Kanban, UpdateKanbanCardDto>(
  `/kanban/card/update`,
  (dto) => KanbanAxios.updateKanbanCard(dto),
);

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getUserDefaultKanban.pending, (state, action) => {
      state.kanbanLoading = true;
    });
    addCase(getUserDefaultKanban.fulfilled, (state, action) => {
      state.kanbanLoading = false;
      state.kanban = action.payload;
    });
    addCase(getUserDefaultKanban.rejected, (state, action) => {
      state.kanbanLoading = false;
    });

    addCase(updateKanbanCard.fulfilled, (state, action) => {
      state.kanban = action.payload;
    });

    addCase(userActions.logout.fulfilled, (state, action) => {
      state.kanban = undefined;
    });
  },
});

export const kanbanActions = {
  getUserDefaultKanban,
  getKanbanCardsByKanbanColumnId,
  createKanbanCard,
  updateKanbanCard,
};

export default kanbanSlice.reducer;
