import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FormAxios } from '../../axios/form/form.axios';
import { Form } from '../../model/form/Form';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { DeleteFormDto } from '../../model/form/dto/DeleteFormDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface FormReducer {
  forms: Form[];
  currentForm: Form | null;
}

const initialState = Object.freeze<FormReducer>({
  forms: [],
  currentForm: null,
});

const getMyForms = AtiraThunk<Form[], { userId: string }>(`/forms/all`, (dto) =>
  FormAxios.getMyForms(dto.userId),
);

const createForm = AtiraThunk<void, CreateFormDto>(`/forms/create`, (dto) =>
  FormAxios.createForm(dto),
);

const deleteForm = AtiraThunk<void, DeleteFormDto>(`/form/delete`, (dto) =>
  FormAxios.deleteForm(dto),
);

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setCurrentForm: (state, action: PayloadAction<Form>) => {
      state.currentForm = action.payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getMyForms.fulfilled, (state, action) => {
      state.forms = action.payload;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const formActions = {
  getMyForms,
  createForm,
  deleteForm,
  ...formSlice.actions,
};

export default formSlice.reducer;
