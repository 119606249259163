import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Images } from '../../assets';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import {
  GetStartedComponents,
  useGetStartedContext,
} from './GetStartedContext';
import { Login } from './components/Login';
import { SignupFormFooter } from './components/SignupLoginFooter';
import { ForgotPassword } from './components/forgot-password/ForgotPassword';
import { Signup } from './components/signup/Signup';

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.black};
  background-color: ${(props) => props.theme.transparent};
  font-size: 1.75rem;
`;

export const Register: React.FC = () => {
  const { t } = useTranslation();

  const { page, onChangePage } = useGetStartedContext();
  const activePage = page;

  const getComponent = {
    [GetStartedComponents.SIGNUP]: Signup,
    [GetStartedComponents.LOGIN]: Login,
    [GetStartedComponents.FORGOT_PASSWORD]: ForgotPassword,
  };

  const CurrentComponent = getComponent[activePage];

  const title =
    page === GetStartedComponents.FORGOT_PASSWORD
      ? t(`common.${GetStartedComponents.SIGNUP}`)
      : t(
          `common.${page === GetStartedComponents.LOGIN ? GetStartedComponents.SIGNUP : GetStartedComponents.LOGIN}`,
        );

  const onTogglePage = () =>
    onChangePage(
      page === GetStartedComponents.LOGIN
        ? GetStartedComponents.SIGNUP
        : GetStartedComponents.LOGIN,
    );

  return (
    <Flex width="100%" height="100%">
      <Flex
        width="50%"
        height="100%"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
        marginLeft="xxl"
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            width="25rem"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="xl">AM CRM</Text>
            <StyledButton onClick={onTogglePage}>{title}</StyledButton>
          </Flex>
        </Flex>

        <CurrentComponent />

        <Flex marginBottom="xl">
          <SignupFormFooter
            setPage={() => onChangePage(GetStartedComponents.FORGOT_PASSWORD)}
            links={[
              { to: '', title: t('login_signup.online_demo') },
              { to: '', title: t('common.privacy_policy') },
            ]}
          />
        </Flex>
      </Flex>

      <Flex
        width="50%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Flex width="50%">
            <AtiraImage src={Images.AtiraGrupIcon} width="100%" />
          </Flex>
          <Text fontSize="xxl" color="lighterPurple" align="center">
            {t('common.atira_space.title')}
          </Text>
          <Text fontSize="l" color="lighterPurple" align="center">
            {t('common.atira_space.description')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
