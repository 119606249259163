import { FormLimitByUserKind } from '../../model/form/types/FormLimitByUserKind';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { RootState } from '../../redux/store';

export function canCreateForm(state: RootState): boolean {
  const user = state.user.loggedInUser;
  const forms = state.form.forms?.filter((f) => f.orphan !== true);

  if (!user || !forms) {
    return false;
  }

  if (
    (user.kind === UserKind.FREE &&
      forms.length >= FormLimitByUserKind[user.kind]) ||
    (user.kind === UserKind.PAID &&
      forms.length >= FormLimitByUserKind[user.kind])
  ) {
    return false;
  }

  return true;
}
