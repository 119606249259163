import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { Tooltip } from 'antd';
import { Dropdown, MenuProps, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Images } from '../../assets';
import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { NotificationsDrawer } from './components/NotificationsDrawer';

const StyleLink = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }
`;

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  white-space: wrap;
  text-overflow: wrap;
  word-break: break-all;
`;

export const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [unreadNotifications, setunreadNotifications] = useState(false);

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLanguage = i18n.language.toUpperCase();

  const isStaging = process.env.REACT_APP_ENV === 'staging';

  const onLogout = () => {
    dispatch(userActions.logout());
    navigate('/register', { replace: true });
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: '1',
          label: (
            <Link to={'/profile'} style={{ cursor: 'pointer' }}>
              <StyledText fontSize="m" color="black">
                {loggedInUser?.name || 'User Name'}
              </StyledText>
            </Link>
          ),
        },
        {
          key: '2',
          label: (
            <StyledText fontSize="m" color="black">
              {loggedInUser?.email || 'User email'}
            </StyledText>
          ),
        },
      ],
    },
    {
      key: '3',
      label: <hr />,
    },
    {
      key: 'g2',
      style: { height: '10rem' },
      type: 'group',
      children: [
        {
          key: '4',
          label: (
            <Link to={'/profile'}>
              <Text fontSize="m">{t('common.account_information')}</Text>
            </Link>
          ),
        },
        {
          key: '5',
          label: (
            <Link to={'/help/help'}>
              <Text fontSize="m">{t('common.help_support')}</Text>
            </Link>
          ),
        },
      ],
    },
    {
      key: '6',
      label: <hr />,
    },
    {
      key: '7',
      label: (
        <Button
          onClick={onLogout}
          fontSize="1rem"
          backgroundColor="transparent"
          color="black"
          borderRadius="0.5rem"
          border={`0.1rem solid ${theme.main}`}
          textAlign="center"
          width="100%"
        >
          {t('header.logout')}
        </Button>
      ),
      className: 'logout-button',
    },
  ];

  const languages: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: 'en',
          label: <Text>EN 🇺🇸</Text>,
          onClick: () => changeLanguage('en'),
        },
        {
          key: 'tr',
          label: <Text>TR 🇹🇷</Text>,
          onClick: () => changeLanguage('tr'),
        },
        {
          key: 'ar',
          label: <Text>AR 🇸🇦</Text>,
          onClick: () => changeLanguage('ar'),
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        width: '100%',
        zIndex: '100',
        height: '3.5rem',
      }}
    >
      <Flex
        background={theme.main}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height={'100%'}
        gap="xl"
        padding="s"
      >
        <Flex alignItems="center" width={'17rem'} paddingLeft="s">
          <StyleLink to={'/'}>
            <AtiraImage src={Images.AtiraSpaceLogo} width="100%" />
          </StyleLink>
        </Flex>

        {isStaging ? (
          <Text color="red" fontSize="l">
            /\ STAGING ENV /\
          </Text>
        ) : null}

        <Flex
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="row-reverse"
          marginRight="m"
          width={'20rem'}
          gap="m"
        >
          <Space direction="vertical">
            <Dropdown
              overlayStyle={{ width: '15rem', borderRadius: '0.5rem' }}
              menu={{ items }}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
              trigger={['click']}
            >
              <UserProfileWrapper>
                {loggedInUser?.picture ? (
                  <AtiraImage src={loggedInUser.picture} />
                ) : (
                  <AtiraIcon icon={faCircleUser} size="3x" color="sub" />
                )}
              </UserProfileWrapper>
            </Dropdown>
          </Space>

          <Tooltip title={t('common.notifications')}>
            <Button
              onClick={() => setOpen(true)}
              backgroundColor="transparent"
              margin="0"
              padding="0"
              icon={unreadNotifications ? faBell : faBell}
              color="sub"
              iconWidth="2x"
            />
          </Tooltip>

          <NotificationsDrawer open={open} onClose={() => setOpen(false)} />

          <Dropdown
            menu={{ items: languages }}
            trigger={['click']}
            arrow={{ pointAtCenter: true }}
            placement="bottom"
          >
            <Button padding="0" margin="0">
              <Flex alignItems="center" gap="s">
                <AtiraIcon icon={faGlobe} size="2x" />
                <Text color="white">{currentLanguage}</Text>
              </Flex>
            </Button>
          </Dropdown>

          {loggedInUser?.kind !== UserKind.PAID && (
            <StyleLink to={'/subscription'}>
              {t('header.subscription')}
            </StyleLink>
          )}
        </Flex>
      </Flex>
    </div>
  );
};
