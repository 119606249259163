import { Kanban } from '../../model/kanban/Kanban';
import { KanbanCard } from '../../model/kanban/KanbanCard';
import { CreateKanbanCardDto } from '../../model/kanban/dto/CreateKanbanCardDto';
import { GetKanbanCardByIdDto } from '../../model/kanban/dto/GetKanbanCardByIdDto';
import { GetKanbanCardsByKanbanColumnIdDto } from '../../model/kanban/dto/GetKanbanCardsByKanbanColumnIdDto';
import { GetUserDefaultKanbanDto } from '../../model/kanban/dto/GetUserDefaultKanbanDto';
import { UpdateKanbanCardDto } from '../../model/kanban/dto/UpdateKanbanCardDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { AtiraApi } from '../AxiosClient';

export class KanbanAxios {
  static getUserDefaultKanban(dto: GetUserDefaultKanbanDto) {
    return AtiraApi.post<Kanban>(`/kanban/default`, dto).then(
      ({ data }) => data,
    );
  }

  static getKanbanCardsByKanbanColumnId(
    dto: GetKanbanCardsByKanbanColumnIdDto,
  ) {
    return AtiraApi.post<PageResponseDto<KanbanCard[]>>(
      `/kanban/column/${dto.columnId}`,
      dto,
    ).then(({ data }) => data);
  }

  static createKanbanCard(dto: CreateKanbanCardDto) {
    return AtiraApi.post<void>(`/kanban/card`, dto).then(({ data }) => data);
  }

  static getKanbanCardById(dto: GetKanbanCardByIdDto) {
    return AtiraApi.post<KanbanCard>(`/kanban/card/${dto.cardId}`, dto).then(
      ({ data }) => data,
    );
  }

  static updateKanbanCard(dto: UpdateKanbanCardDto) {
    return AtiraApi.patch<Kanban>(`/kanban/card`, dto).then(({ data }) => data);
  }
}
