import React from 'react';

import { Flex } from './Flex';
import { Text } from './Text';

interface StepperStepProps {
  title: string;
  children: React.ReactNode;
}

export const StepperStep: React.FC<StepperStepProps> = ({
  title = 'Step',
  children,
}) => {
  return (
    <Flex
      width="100%"
      flexDirection="column"
      gap="xxl"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex>
        <Text fontWeight="bold" fontSize="xl">
          {title}
        </Text>
      </Flex>
      <Flex width="100%" height={'100%'} justifyContent="center">
        {children}
      </Flex>
    </Flex>
  );
};
