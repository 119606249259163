import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { GoogleButton } from '../../../components/logins/GoogleButton';
import { LoginDto } from '../../../model/user/dto/LoginDto';
import { useAppDispatch } from '../../../redux/store';
import { userActions } from '../../../redux/user/user.slice';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledButton = styled(Button)`
  width: 15rem;
  height: 3rem;
  border-radius: 0.5rem;
  font-size: 20px;
  margin-top: 50%;
  color: black;
  background-color: ${({ theme }) => theme.lightgray};
`;

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm<LoginDto>({
    defaultValues: { email: '', password: '' },
  });

  const { t } = useTranslation();

  const onNext = async () => {
    try {
      setLoading(true);
      await dispatch(userActions.login(getValues())).unwrap();
      navigate('/dashboard', { replace: true });
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="m"
          padding="m"
        >
          <Controller
            name="email"
            rules={{
              required: true,
              pattern: /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/,
            }}
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <StyledInput
                title={t('common.email')}
                type="email"
                value={value}
                onChange={onChange}
                id="email"
                valid={isEmpty(errors.email)}
                errorMessage={errors.email?.message || t('signup.error.email')}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <StyledInput
                title={t('common.password')}
                type="password"
                value={value}
                onChange={onChange}
                id="password"
                valid={isEmpty(errors.password)}
                errorMessage={
                  errors.password?.message || t('signup.error.password')
                }
              />
            )}
          />

          <StyledButton loading={loading} onClick={handleSubmit(onNext)}>
            {t('common.login')}
          </StyledButton>
        </Flex>
        <GoogleButton
          backgroundColor="lightergray"
          borderRadius="0.5rem"
          color="black"
          width="15rem"
          height="3rem"
        />
      </Flex>
    </Flex>
  );
};
