import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTable } from '../../../../components/AtiraTable';
import { Flex } from '../../../../components/Flex';
import { AtriaTourClasses } from '../../../../components/tour/AtiraTourClasses.enum';
import { entrySliceSelectors } from '../../../../redux/entry/entry.selector';
import { entryActions } from '../../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { createEntriesWidgetTableColumns } from '../../../entries/utils/createEntriesWidgetTableColumns';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  overflow-y: scroll;
  width: 100%;
  height: 14rem;
  padding: 0;
`;

export const WidgetLatestEntries: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const entries = useAppSelector(entrySliceSelectors.selectLatestEntries);
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );

  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );

  useEffect(() => {
    dispatch(
      entryActions.getLatestEntries({
        userId,
        count: 10,
        page: 0,
      }),
    );
    dispatch(entryActions.getUserTableSettings({ userId }));
  }, [userId, dispatch]);

  const tableColumns = useMemo(
    () => createEntriesWidgetTableColumns(tableSettings?.columns || [], { t }),
    [tableSettings?.columns, t],
  );

  return (
    <Container>
      <Flex
        flexDirection="column"
        width="100%"
        className={AtriaTourClasses.WIDGET_LATEST_ENTRIES}
      >
        <AtiraTable
          size="small"
          columns={tableColumns}
          data={entries}
          loading={entriesLoading && !entries.length}
          rowSelection={undefined}
          pagination={false}
        />
      </Flex>
    </Container>
  );
};
