import { createSlice } from '@reduxjs/toolkit';

import { DashboardAxios } from '../../axios/dashboard/dashboard.axios';
import { DashboardStats } from '../../model/dashboard/DashboardStats';
import { GetDashboardStatsDto } from '../../model/dashboard/dto/GetDashboardStatsDto';
import { GetDashboardStatsResponseDto } from '../../model/dashboard/dto/GetDashboardStatsResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface DashboardReducer {
  dashboard: DashboardStats;
}

const initialState = Object.freeze<DashboardReducer>({
  dashboard: {
    entries: {
      categories: { JUNK: 0, LEAD: 0, CONTACT: 0, ENTRY: 0 },
      total: 0,
    },
    reports: { total: 0 },
    forms: { total: 0, categories: { JUNK: 0, ACTIVE: 0, DRAFT: 0 } },
  },
});

const getDashboardStats = AtiraThunk<
  GetDashboardStatsResponseDto,
  GetDashboardStatsDto
>('/dashboard/stats', (dto) => DashboardAxios.getDashoardStats(dto));

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getDashboardStats.fulfilled, (state, action) => {
      state.dashboard = action.payload;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const dashboardActions = {
  getDashboardStats,
};

export default dashboardSlice.reducer;
