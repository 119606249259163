import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { Spacing } from '../../../../theme/Spacing';

const Wrapper = styled(Flex)`
  max-width: 45vw;
  width: 95%;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: ${Spacing.s};
`;

const Container = styled(Flex)<{ generic: boolean }>`
  background-color: ${({ theme, generic }) =>
    generic ? theme.darkSub : theme.white};
  border-radius: 1rem;
  overflow-y: scroll;
  width: 100%;
  height: 14rem;
  padding: ${({ generic }) => (generic ? '0' : '1rem')};
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 5;
  right: 5;
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  padding: 0.2rem 0;
`;

type WidgetWrapperProps = {
  title: string;
  Component: React.FC;
  onDelete?: VoidFunction;
  generic?: boolean;
};

export const WidgetWrapper: React.FC<WidgetWrapperProps> = ({
  title,
  Component,
  onDelete,
  generic = false,
}) => {
  return (
    <Wrapper>
      <Title fontSize="l">{title}</Title>
      <Flex>
        <Component />
        {onDelete ? (
          <DeleteButton onClick={onDelete} icon={faX} width="20" />
        ) : null}
      </Flex>
    </Wrapper>
  );
};
