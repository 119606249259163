import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { Tooltip } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { SortableChild } from '../../../components/dnd/SortableChild';
import { KanbanCard as KanbanCardModel } from '../../../model/kanban/KanbanCard';
import { KanbanColumn } from '../../../model/kanban/KanbanColumn';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { KanbanEditCardDrawer } from './KanbanEditCardDrawer';

const StyledKanbanCard = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  padding: ${Spacing.s};
  background-color: ${(props) => props.theme.white};
  border-radius: 1rem;
  width: 100%;
`;

const KnabanCardName = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
`;

const KanbanCardAction = styled(Button)`
  padding: 0;
  background-color: ${(props) => props.theme.transparent};
  margin: 0;
`;

type EntriesDealsDealPros = {
  card: KanbanCardModel;
  column?: KanbanColumn;
};

export const KanbanCard: React.FC<EntriesDealsDealPros> = ({
  card,
  column,
}) => {
  const [kanbanCardDrawerVisible, setKanbanCardDrawerVisible] = useState(false);

  const openEditKanbanCard = () => {
    setKanbanCardDrawerVisible(true);
  };

  return (
    <SortableChild id={card._id} showDragIcon key={card._id}>
      <StyledKanbanCard>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          gap="s"
          width={'100%'}
        >
          <Flex>
            <Flex width={'50%'}>
              <KnabanCardName cutText>{card.title}</KnabanCardName>
            </Flex>
            <Flex width={'50%'} justifyContent="flex-end">
              <Flex gap="m" alignItems="center">
                <KanbanCardAction>
                  <Tooltip>
                    <AtiraIcon color="main" icon={faEye} />
                  </Tooltip>
                </KanbanCardAction>
                <KanbanCardAction onClick={openEditKanbanCard}>
                  <Tooltip>
                    <AtiraIcon color="main" icon={faPenToSquare} />
                  </Tooltip>
                </KanbanCardAction>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Text cutText>
              {card.entry?.fields?.name || card.entry?.fields?.email}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" marginTop="l">
            <Text>{card.amount}$</Text>

            {card.closingDate ? (
              <Text>
                {standardDate(new Date(card.closingDate).toISOString())}
              </Text>
            ) : null}
          </Flex>
        </Flex>
      </StyledKanbanCard>

      <KanbanEditCardDrawer
        key={card._id}
        isOpen={kanbanCardDrawerVisible}
        onClose={() => setKanbanCardDrawerVisible(false)}
        kanbanColumn={column}
        kanbanCard={card}
      />
    </SortableChild>
  );
};
