import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AtiraStepper } from '../../components/AtiraStepper';
import { Flex } from '../../components/Flex';
import { StepperStep } from '../../components/StepperStep';
import { SubHeader } from '../../components/SubHeader';
import i18n from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { CreateFormDtoInputNames } from '../../model/form/types/CreateFormDtoInputNames.enum';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { useAppSelector } from '../../redux/store';
import { FormCreateStepBasicInfo } from './FormCreateStepBasicInfo';
import { FormCreateStepInputs } from './FormCreateStepInputs';
import { FormCreateStepTheme } from './FormCreateStepTheme';
import { canCreateForm } from './FormUtils';

export const FormCreate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const websiteLanguage = i18n.language;

  const formMethods = useForm<CreateFormDto>({
    defaultValues: {
      inputs: [
        {
          name: CreateFormDtoInputNames.NAME,
          label: t(`common.${CreateFormDtoInputNames.NAME}`),
          required: true,
          type: InputTypes.text,
        },
        {
          name: CreateFormDtoInputNames.EMAIL,
          label: t(`common.${CreateFormDtoInputNames.EMAIL}`),
          required: true,
          type: InputTypes.email,
        },
        {
          name: CreateFormDtoInputNames.PHONE,
          label: t(`common.${CreateFormDtoInputNames.PHONE}`),
          required: false,
          type: InputTypes.number,
        },
        {
          name: CreateFormDtoInputNames.MESSAGE,
          label: t(`common.${CreateFormDtoInputNames.MESSAGE}`),
          required: false,
          type: InputTypes.text,
        },
      ],
      postSubmit: FormPostSubmit.redirect,
      domains: [{ content: '' }],
      theme: FormTheme.white,
      title: '',
    },
  });

  const state = useAppSelector((s) => s);

  useEffect(() => {
    if (!canCreateForm(state)) {
      navigate('/forms', { replace: true });
    }
  }, [navigate, state]);

  return (
    <FormProvider {...formMethods}>
      <Flex width={'100%'} flexDirection="column">
        <SubHeader
          title={`${t('common.forms')} / ${t('common.create')}`}
          icon={websiteLanguage === 'ar' ? faArrowRight : faArrowLeft}
          buttonTitle={t('common.back')}
          onClick={() => navigate('/forms')}
        />
        <AtiraStepper
          components={[
            <StepperStep
              title={t('forms.create.step.1')}
              children={<FormCreateStepBasicInfo />}
            />,
            <StepperStep
              title={t('forms.create.step.2')}
              children={<FormCreateStepInputs />}
            />,
            <StepperStep
              title={t('forms.create.step.3')}
              children={<FormCreateStepTheme />}
            />,
          ]}
          items={[
            { title: t('forms.create.step.1') },
            { title: t('forms.create.step.2') },
            { title: t('forms.create.step.3') },
          ]}
        />
      </Flex>
    </FormProvider>
  );
};
