import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AtiraError } from '../../components/AtiraError';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { WarningModal } from '../../components/WarningModal';
import { Form } from '../../model/form/Form';
import { FormLimitByUserKind } from '../../model/form/types/FormLimitByUserKind';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { formActions } from '../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import { CreateNewEntryModal } from '../entries/components/CreateNewEntryModal';
import { canCreateForm } from './FormUtils';
import { PreviewFormModal } from './components/PreviewFormModal';
import { ShareFormModal } from './components/ShareFormModal';
import { UserForm } from './components/UserForm';

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 ${Spacing.m};
  flex-direction: column;
  gap: ${() => `${Spacing.m}`};
  width: 100%;
  height: 100%;
  align-content: space-between;
`;

export const UserForms: React.FC = () => {
  const { t } = useTranslation();

  const [hoveredFormId, setHoveredFormId] = useState<string | null>(null);
  const [loading, setloading] = useState(false);
  const [shareFormModalVisible, setShareFormModalVisible] = useState(false);
  const [previewModalVisible, setPreviewFormModalVisible] = useState(false);
  const [deleteFormModalVisible, setDeleteFormModalVisible] = useState(false);
  const [createNewEntryModalVisible, setCreateNewEntryModalVisible] =
    useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userForms = useAppSelector(formSliceSelectors.selectMyForms)?.filter(
    (f) => f.orphan !== true,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const state = useAppSelector((s) => s);
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const getCanCreateForm = () => {
    if (user.kind === UserKind.FREE) {
      return userForms.length <= FormLimitByUserKind.FREE;
    } else {
      return userForms.length < FormLimitByUserKind.FREE;
    }
  };

  const createNewForm = () => {
    if (getCanCreateForm()) {
      navigate('/forms/create');
    } else {
      if (user.kind === UserKind.FREE) {
        AtiraToast.error(t('forms.create.modal.creation.limit.free'));
      } else {
        AtiraToast.error(t('forms.create.modal.creation.limit.paid'));
      }
    }
  };

  const openShareModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setShareFormModalVisible(true);
  };

  const openPreviewModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setPreviewFormModalVisible(true);
  };

  const navigateToFormEntries: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    navigate('/entries', { state: { useFilter: form } });
  };

  const openCreateNewEntryModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setCreateNewEntryModalVisible(true);
  };

  const openDeleteFormModal: ATVoidFunction<Form> = (form) => {
    dispatch(formActions.setCurrentForm(form));
    setDeleteFormModalVisible(true);
  };

  const deleteForm = async () => {
    try {
      if (!currentForm) {
        return AtiraToast.error('Missing Form!');
      }

      setloading(true);
      await dispatch(
        formActions.deleteForm({ formId: currentForm?._id!, userId: userId }),
      ).unwrap();
      await dispatch(formActions.getMyForms({ userId: userId })).unwrap();
      setDeleteFormModalVisible(false);
      AtiraToast.success(t('forms.form.delete.success'));
    } catch (error: any) {
      console.log(error);
      AtiraToast.apiError(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    dispatch(formActions.getMyForms({ userId: userId }));
  }, [dispatch, userId]);

  return (
    <Flex flexDirection="column">
      <SubHeader
        title={t('common.forms')}
        icon={faPlus}
        buttonTitle={t('common.create')}
        onClick={createNewForm}
        enabled={canCreateForm(state)}
      />

      <Wrapper>
        {userForms.length ? (
          userForms.map((f, i) => (
            <UserForm
              key={f._id}
              form={f}
              onHover={setHoveredFormId}
              hoveredFormId={hoveredFormId}
              onDelete={openDeleteFormModal}
              onShare={openShareModal}
              onPreview={openPreviewModal}
              onNavigateToEntries={navigateToFormEntries}
              onCreate={openCreateNewEntryModal}
            />
          ))
        ) : (
          <Flex alignSelf="center">
            <AtiraError errorMessage={t('forms.no_forms_yet')} />
          </Flex>
        )}
      </Wrapper>

      <ShareFormModal
        open={shareFormModalVisible}
        onClose={() => setShareFormModalVisible(false)}
      />

      <PreviewFormModal
        open={previewModalVisible}
        onClose={() => setPreviewFormModalVisible(false)}
      />

      <WarningModal
        open={deleteFormModalVisible}
        title={t('forms.delete.modal.title')}
        onConfirm={deleteForm}
        loading={loading}
        onClose={() => setDeleteFormModalVisible(false)}
      />

      <CreateNewEntryModal
        isOpen={createNewEntryModalVisible}
        onClose={() => setCreateNewEntryModalVisible(false)}
        key={currentForm?._id}
      />
    </Flex>
  );
};
