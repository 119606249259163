import { Drawer } from 'antd';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import omitBy from 'lodash/omitBy';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { KanbanCard } from '../../../model/kanban/KanbanCard';
import { KanbanColumn } from '../../../model/kanban/KanbanColumn';
import { UpdateKanbanCardDto } from '../../../model/kanban/dto/UpdateKanbanCardDto';
import { entryActions } from '../../../redux/entry/entry.slice';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledDropdown = styled(DropDown)`
  height: 2.3rem;
`;

const StyledInput = styled(Input)`
  height: 2.3rem;
  border-radius: 0.3rem;

  &::placeholder {
    font-size: 1.5rem;
  }
`;

const AmountCurrency = styled.span`
  position: absolute;
  right: 0.5rem;
  bottom: 12%;
  font-size: 1.2rem;
`;

const SubmitButton = styled(Button)`
  height: 2.3rem;
  border-radius: 0.3rem;
  width: 50%;
  margin-left: auto;
`;

type KanbanCardDrawerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  kanbanColumn?: KanbanColumn;
  kanbanCard: KanbanCard;
};

export const KanbanEditCardDrawer: React.FC<KanbanCardDrawerProps> = ({
  isOpen = false,
  onClose,
  kanbanColumn,
  kanbanCard,
}) => {
  const [createKanbanCardLoading, setCreateKanbanCardLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { control, handleSubmit, getValues, reset, watch } =
    useForm<UpdateKanbanCardDto>();

  const userKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const values = watch();

  const onCreateKanbanCrad = async () => {
    try {
      setCreateKanbanCardLoading(true);

      const dto = getValues();

      const payload = omitBy(
        {
          cardId: kanbanCard?._id!,
          kanbanId: userKanban?._id!,
          columnId: dto.columnId ? dto.columnId : kanbanColumn?._id,
          userId,
          amount: dto.amount,
          closingDate: dto.closingDate
            ? new Date(dto.closingDate).getTime()
            : null,
          note: dto.note,
          title: dto.title,
          newOrder: dto.newOrder,
          oldOrder: dto.oldOrder,
        },
        isNil,
      ) as unknown as UpdateKanbanCardDto;

      await dispatch(kanbanActions.updateKanbanCard(payload)).unwrap();

      localOnClose();
      AtiraToast.success(t('deals.drawer.update.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setCreateKanbanCardLoading(false);
    }
  };

  const localOnClose = () => {
    dispatch(entryActions.emptySearchEntries());
    onClose();
  };

  useEffect(() => {
    reset({
      title: kanbanCard?.title || '',
      kanbanId: userKanban?._id || '',
      columnId: kanbanColumn?._id || kanbanCard?.parentId || '',
      note: kanbanCard?.note || '',
      closingDate: kanbanCard?.closingDate || new Date().getTime(),
      amount: kanbanCard?.amount || '',
    });
  }, [kanbanCard, kanbanColumn, reset, userKanban]);

  return (
    <Drawer
      destroyOnClose
      title={t('deals.drawer.edit.title')}
      open={isOpen}
      onClose={localOnClose}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('deals.drawer.input.deal_name')}
            />
          )}
        />

        <StyledDropdown
          onChange={noop}
          showSearch
          filterOption={false}
          value={
            kanbanCard.entry?.fields.name ||
            kanbanCard.entry?.fields.email ||
            kanbanCard.entryId
          }
          title={t('deals.drawer.input.contact_name')}
          disabled
        />

        <Controller
          name="columnId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledDropdown
              defaultValue={{
                label: kanbanColumn?.name,
                value: kanbanColumn?._id,
              }}
              value={value}
              options={userKanban?.columns?.map(({ _id, name }) => ({
                label: name,
                value: _id,
              }))}
              onChange={onChange}
              title={t('common.stage')}
            />
          )}
        />

        <Flex position="relative">
          <Controller
            name="amount"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                title={t('common.amount')}
              />
            )}
          />
          <AmountCurrency>$</AmountCurrency>
        </Flex>

        <Controller
          name="closingDate"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <StyledInput
                onChange={(e) => onChange(e)}
                title={t('deals.drawer.input.closing_date')}
                type="date"
                value={
                  value
                    ? new Date(value).toISOString().substring(0, 10)
                    : undefined
                }
              />
            );
          }}
        />

        <Controller
          name="note"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('common.description')}
              type="text"
            />
          )}
        />

        <SubmitButton
          onClick={handleSubmit(onCreateKanbanCrad)}
          loading={createKanbanCardLoading}
          disabled={!values.title || !values.columnId}
        >
          {t('common.edit')}
        </SubmitButton>
      </Flex>
    </Drawer>
  );
};
