import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.gray};
  font-size: 1.1rem;
  cursor: not-allowed;
`;

const ForgotPassButton = styled(Button)`
  color: ${({ theme }) => theme.black};
  background-color: ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.gray};
  font-size: 1rem;
  padding: 0;
`;

interface SignupFormFooterProps {
  links: { title: string; to: string }[];
  setPage: VoidFunction;
}

export const SignupFormFooter: React.FC<SignupFormFooterProps> = ({
  links,
  setPage,
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" gap="xxxl" alignItems="center">
        <ForgotPassButton onClick={setPage}>
          {t('login_signup.forgot_password')}
        </ForgotPassButton>
        <Text fontSize="m">
          <StyledLink to={links[0].to}>{links[0].title}</StyledLink>
        </Text>
      </Flex>

      <Flex justifyContent="space-between" borderTop={'3px solid grey'}>
        <Text>
          <StyledLink to={links[1].to}>{links[1].title}</StyledLink>
        </Text>
      </Flex>
    </Flex>
  );
};
