import React, { ReactNode, createContext, useContext, useState } from 'react';

export enum GetStartedComponents {
  LOGIN = 'login',
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'forgot-password',
}

interface LoginSignupContextType {
  page: GetStartedComponents;
  onChangePage: (newPage: GetStartedComponents) => void;
}

const GetStartedContext = createContext<LoginSignupContextType>({
  page: GetStartedComponents.SIGNUP,
  onChangePage: () => ({}),
});

export const useGetStartedContext = () => {
  const context = useContext(GetStartedContext);

  return context;
};

interface GetStartedProviderProps {
  children: ReactNode;
}

export const GetStartedProvider: React.FC<GetStartedProviderProps> = ({
  children,
}) => {
  // DO NOT change the default page
  const [page, setPage] = useState<GetStartedComponents>(
    GetStartedComponents.LOGIN,
  );

  const onChangePage = (newPage: GetStartedComponents) => {
    setPage(newPage);
  };

  return (
    <GetStartedContext.Provider value={{ page, onChangePage }}>
      {children}
    </GetStartedContext.Provider>
  );
};
