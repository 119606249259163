import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import pick from 'lodash/pick';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { UpdateUserDto } from '../../model/user/dto/UpdateUserDto';
import { SignupMethod } from '../../model/user/types/SignupMethod';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { AtiraToast } from '../../utils/AtiraToast';
import { standardDate } from '../../utils/Date';

const StyledButton = styled(Button)`
  width: 20rem;
  height: 3.2rem;
  border-radius: 0.5rem;
`;

const StyledInput = styled(Input)`
  width: 20rem;
  height: 3.2rem;
  border: 0.1rem solid ${(props) => props.theme.black};
  border-radius: 0.5rem;
`;

export const Profile: React.FC = () => {
  const [upadteInfoLoading, setUpadteInfoLoading] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const { control, getValues, handleSubmit } = useForm<
    Omit<UpdateUserDto, 'userId'>
  >({
    defaultValues: {
      name: loggedInUser.name || '',
      phone: loggedInUser.phone || '',
      company: loggedInUser.company || '',
      website: loggedInUser.website || '',
    },
  });

  const updateInfo = async () => {
    try {
      const updatedDto = getValues();
      const dtoToUpdate = {} as UpdateUserDto;

      const baseDto: Partial<UpdateUserDto> = pick(loggedInUser, [
        'name',
        'company',
        'phone',
        'website',
      ]);

      Object.entries(updatedDto).forEach(([key, value]) => {
        if (baseDto[key as keyof UpdateUserDto] !== value) {
          dtoToUpdate[key as keyof UpdateUserDto] = value;
        }
      });

      if (Object.keys(dtoToUpdate).length === 0) {
        AtiraToast.success(t('profile.update.success'));
      } else {
        setUpadteInfoLoading(true);

        dtoToUpdate.userId = loggedInUser._id;

        await dispatch(userActions.updateUserInfo(dtoToUpdate)).unwrap();
        await dispatch(
          userActions.getLoggedInUser({ userId: loggedInUser._id }),
        ).unwrap();

        AtiraToast.success(t('profile.update.success'));
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setUpadteInfoLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      padding="m"
      gap="xxl"
      flexWrap="wrap"
    >
      <Text align="center" fontSize="xxl">
        {t('profile.title.account_info')}
      </Text>

      <Flex flex={1}>
        <Flex flexDirection="column" alignItems="center" gap="m" width="30%">
          <Flex justifyContent="center" width="10rem" height="10rem">
            {loggedInUser.picture ? (
              <AtiraImage
                src={loggedInUser?.picture}
                width="100%"
                height="100%"
                style={{ borderRadius: '50%' }}
              />
            ) : (
              <AtiraIcon icon={faCircleUser} size="10x" color="main" />
            )}
          </Flex>
          <Text fontSize="m">
            {t('common.email')}: {loggedInUser?.email || 'User email'}
          </Text>
          <Text fontSize="m">
            {t('profile.join_date')} {standardDate(loggedInUser?.createdAt)}
          </Text>
        </Flex>

        <Flex
          width="70%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          gap="m"
        >
          <Flex gap="m">
            <Flex flexDirection="column" gap="m">
              <Text align="start" fontSize="xxl">
                {t('profile.title.personal_info')}
              </Text>
              <Flex flexDirection="column" gap="m">
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      type="text"
                      placeholder="Name"
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      type="number"
                      placeholder={t('common.phone')}
                    />
                  )}
                />
              </Flex>
            </Flex>

            <Flex flexDirection="column" gap="m">
              <Text align="start" fontSize="xxl">
                {t('profile.title.company_info')}
              </Text>
              <Flex flexDirection="column" gap="m">
                <Controller
                  name="company"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      type="text"
                      placeholder={t('common.company')}
                    />
                  )}
                />
                <Controller
                  name="website"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <StyledInput
                      value={value}
                      onChange={onChange}
                      type="text"
                      placeholder={t('common.website')}
                    />
                  )}
                />
              </Flex>
            </Flex>
          </Flex>

          <StyledButton
            onClick={handleSubmit(updateInfo)}
            loading={upadteInfoLoading}
          >
            {t('profile.btn.update_info')}
          </StyledButton>
        </Flex>
      </Flex>

      <Flex flex={1} justifyContent="center" flexWrap="wrap">
        {loggedInUser!.signupMethod === SignupMethod.GOOGLE ? (
          <Flex width="50%" justifyContent="center">
            <Flex flexDirection="column" gap="m">
              <Text align="start" fontSize="xxl">
                {t('profile.title.sign_up.info')}
              </Text>
              <StyledButton disabled>
                {t('profile.btn.revoke_google')}
              </StyledButton>
            </Flex>
          </Flex>
        ) : null}
        <Flex width="50%" justifyContent="center">
          <Flex flexDirection="column" gap="m">
            <Text align="start" fontSize="xxl">
              {t('profile.title.delete_account')}
            </Text>
            <StyledButton disabled>
              {t('profile.btn.delete_account')}
            </StyledButton>
          </Flex>
        </Flex>

        <StyledButton disabled>{t('profile.btn.update_password')}</StyledButton>
      </Flex>
    </Flex>
  );
};
