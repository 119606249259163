import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { SortableInput } from './components/SortableInput';

export const FormCreateStepInputs: React.FC = () => {
  const { control, register } = useFormContext<CreateFormDto>();

  const { fields, update, replace } = useFieldArray({
    control,
    name: 'inputs',
  });

  const { t } = useTranslation();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const inputOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((f) => f.id === active.id);
      const newIndex = fields.findIndex((f) => f.id === over.id);

      replace(arrayMove(fields, oldIndex, newIndex));
    }
  };

  return (
    <Flex flex={1} alignItems="center" justifyContent="center" gap="xxl">
      <Flex flexDirection="column">
        <Flex maxWidth={'30rem'} marginBottom="xl">
          <Text fontSize="m">{t('forms.create.step.2.form_inputs.note')}</Text>
        </Flex>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={inputOnDragEnd}
        >
          <SortableContext
            items={fields}
            strategy={verticalListSortingStrategy}
          >
            {fields.map((field, i) => (
              <SortableInput
                disabled={field.type === InputTypes.email}
                key={field.id}
                field={field}
                index={i}
                register={register}
                update={update}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Flex>
      <Flex gap={'s'} flexDirection="column" maxWidth={'20rem'}>
        <Text fontSize="xm" fontWeight={500}>
          {t('forms.create.step.2.input.label.post_submit_type')}
        </Text>

        <Flex flexDirection="column">
          <Flex gap="m" alignItems="center">
            <label htmlFor={FormPostSubmit.confirm}>
              {t('common.confirm')}
            </label>
            <input
              id={FormPostSubmit.confirm}
              type="radio"
              value={FormPostSubmit.confirm}
              {...register('postSubmit')}
            />
          </Flex>

          <Text>{t('forms.create.step.2.radio.confirm.description')}</Text>
        </Flex>

        <Flex flexDirection="column">
          <Flex gap="m" alignItems="center">
            <label htmlFor={FormPostSubmit.redirect}>
              {t('forms.create.step.2.radio.redirect')}
            </label>
            <input
              id={FormPostSubmit.redirect}
              type="radio"
              {...register('postSubmit')}
              value={FormPostSubmit.redirect}
            />
          </Flex>
          <Text>{t('forms.create.step.2.radio.redirect.description')}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
