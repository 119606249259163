import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { AtriaTourClasses } from '../../../../components/tour/AtiraTourClasses.enum';
import i18n from '../../../../i18n';
import { EntryKind } from '../../../../model/entry/types/EntryKind.enum';
import { dashboardSliceSelectors } from '../../../../redux/dashboard/dashboard.selector';
import { dashboardActions } from '../../../../redux/dashboard/dashboard.slice';
import { formSliceSelectors } from '../../../../redux/forms/form.selector';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { pluralize } from '../../../../utils/String';
import { CreateNewEntryModal } from '../../../entries/components/CreateNewEntryModal';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  overflow-y: scroll;
  width: 100%;
  height: 14rem;
  padding: 1rem;
`;

const AbsoluteButton = styled(Button)`
  padding: 0;
  margin: 0;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const WidgetStats: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const stats = useAppSelector(dashboardSliceSelectors.selectDashboardStats);
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);
  const [newEntryModalVisible, setNewEntryModalVisible] = useState(false);

  const onOpenNewEntryModal = () => {
    setNewEntryModalVisible(true);
  };

  const onCloseNewEntryModal = () => {
    setNewEntryModalVisible(false);
  };

  useEffect(() => {
    dispatch(dashboardActions.getDashboardStats({ userId: user._id }));
  }, [dispatch, user._id]);

  return (
    <Container>
      <Flex
        flexDirection="column"
        gap="m"
        width={'100%'}
        className={AtriaTourClasses.WIDGET_STATS}
        position="relative"
      >
        <Flex alignItems="center" gap="s">
          <Text fontSize="l" fontWeight="bold" color="gray">
            {t('common.welcome')}
          </Text>
          <Text fontSize="l" fontWeight="bold" color="main">
            {user.name}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="l" color="gray">
            {t('common.your_space')}
          </Text>
        </Flex>

        <Flex gap="s">
          <Flex gap="s">
            <Text color="main" fontSize="l">
              {stats?.entries.categories.LEAD}
            </Text>
            <Text fontSize="l" color="gray">
              {pluralize(
                stats?.entries.categories.CONTACT,
                t('common.lead'),
              )}
            </Text>
            <Text fontSize="l" color="gray">
              &
            </Text>
          </Flex>
          <Flex gap="s">
            <Text color="main" fontSize="l">
              {stats?.entries.categories.CONTACT}
            </Text>
            <Text fontSize="l" color="gray">
              {pluralize(
                stats?.entries.categories.CONTACT,
                t('common.contact'),
              )}
            </Text>
          </Flex>
        </Flex>

        <Flex gap="s">
          <Flex gap="s">
            <Text color="main" fontSize="l">
              {stats?.forms.total}
            </Text>
            <Text fontSize="l" color="gray">
              {pluralize(stats?.forms.total, t('common.forms'))}
            </Text>
            <Text fontSize="l" color="gray">
              &
            </Text>
          </Flex>
          <Flex gap="s">
            <Text color="main" fontSize="l">
              {stats?.reports.total}
            </Text>
            <Text fontSize="l" color="gray">
              {pluralize(stats?.reports.total, t('common.reports'))}
            </Text>
          </Flex>
        </Flex>

        <AbsoluteButton onClick={onOpenNewEntryModal}>
          <Flex alignItems="center" gap="s">
            <AtiraIcon icon={faPlus} color="main" />
            <Text color="main">{t('common.add_new')}</Text>
          </Flex>
        </AbsoluteButton>

        <CreateNewEntryModal
          isOpen={newEntryModalVisible}
          onClose={onCloseNewEntryModal}
          showSelect={forms.length > 0}
          key={currentForm?._id}
        />
      </Flex>
    </Container>
  );
};
