export interface ThemeType {
  purple: string;
  darkerPurple: string;
  lighterPurple: string;
  darkPink: string;
  green: string;
  white: string;
  black: string;
  lightBlack: string;
  gray: string;
  lightergray: string;
  lightgray: string;
  transparent: string;
  blackTransparent: string;
  darkBlackTransparent: string;
  blue: string;
  red: string;
  orange: string;
  //
  main: string;
  sub: string;
  darkSub: string;
  darkerSub: string;
  darkMain: string;
  lightMain: string;
  lightSub: string;
  textColor: string;
  darkTextColor: string;
}
/* eslint-disable */
export const Theme: ThemeType = {
  purple: '#3f0d53',
  darkerPurple: '#2a073c',
  lighterPurple: '#5d1b75 ',
  darkPink: '#803588',
  green: '#4cbb17',
  white: '#fff',
  black: '#000',
  lightBlack: '#222',
  gray: 'gray',
  lightergray: '#c6c6c6',
  lightgray: '#d5d5d5',
  transparent: 'rgba(0,0,0,0)',
  blackTransparent: '#00000080',
  darkBlackTransparent: '#000000E6',
  blue: '#0000ff',
  red: '#ff0000',
  orange: '#ffa500',

  main: '#803588',
  sub: '#ebebeb',
  darkSub: '#d6d6d6',
  darkerSub: '#929292',
  darkMain: ' #e8eaea',
  lightMain: ' #934599',
  lightSub: '#f8f9fa',
  textColor: '#fff',
  darkTextColor: '#000',
};
/* eslint-disable */
export const SecondTheme: ThemeType = {
  purple: '#3f0d53',
  darkerPurple: '#2a073c',
  lighterPurple: '#5d1b75 ',
  darkPink: '#803588',
  green: '#4cbb17',
  white: '#000',
  black: '#fff',
  lightBlack: '#ccc',
  gray: 'gray',
  lightergray: '#c6c6c6',
  lightgray: '#d5d5d5',
  transparent: 'rgba(0,0,0,0)',
  blackTransparent: '#00000080',
  darkBlackTransparent: '#000000E6',
  blue: '#0000ff',
  red: '#ff0000',
  orange: '#ffa500',

  main: '#803588',
  sub: '#ebebeb',
  darkSub: '#d6d6d6',
  darkerSub: '#929292',
  darkMain: ' #e8eaea',
  lightMain: ' #934599',
  lightSub: '#f8f9fa',
  textColor: '#fff',
  darkTextColor: '#000',
};
