import { Drawer } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Entry } from '../../../model/entry/Entry';
import { TaskPriority } from '../../../model/entry/types/TaskPriority.enum';
import { standardDate } from '../../../utils/Date';
import { TaskUpdateDrawer } from './TaskUpdateDrawer';

const BoldText = styled(Text)`
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  border-radius: 0.3rem;
  width: 7rem;
`;

interface TaskPreviewDrawerProps {
  entry: Entry | null;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const TaskPreviewDrawer: React.FC<TaskPreviewDrawerProps> = ({
  entry,
  isOpen = false,
  onClose,
}) => {
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <Drawer
      title={t('task.preview.drawer.title')}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flex={1} flexDirection="column" gap="m">
        <Flex gap="s">
          <BoldText>{t('task.preview.name')}</BoldText>
          <Text>{entry?.task?.name}</Text>
        </Flex>

        <Flex gap="s">
          <BoldText>{t('task.preview.created_for')}</BoldText>
          <Text>{entry?.fields.name}</Text>
        </Flex>

        <Flex gap="s">
          <BoldText>{t('common.due_date')}:</BoldText>
          <Text>
            {entry?.task?.deadline
              ? standardDate(new Date(entry?.task?.deadline).toString())
              : null}
          </Text>
        </Flex>

        <Flex gap="s">
          <BoldText>{t('common.priority')}:</BoldText>
          <Text
            style={{
              color:
                entry?.task?.priority === TaskPriority.LOW
                  ? 'green'
                  : entry?.task?.priority === TaskPriority.MEDIUM
                    ? 'orange'
                    : 'red',
            }}
          >
            {entry?.task?.priority}
          </Text>
        </Flex>

        <Flex gap="s" flexWrap="wrap">
          <BoldText>{t('common.description')}:</BoldText>
          <Flex>
            <Text wordBreak="break-word">{entry?.task?.description}</Text>
          </Flex>
        </Flex>

        <Flex>
          <StyledButton
            title={t('common.edit')}
            onClick={() => {
              onClose();
              setEditDrawerVisible(true);
            }}
          />
        </Flex>
      </Flex>

      <TaskUpdateDrawer
        entry={entry}
        isOpen={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
      />
    </Drawer>
  );
};
