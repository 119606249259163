import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { SignupDto } from '../../../../model/user/dto/SignupDto';
import { useAppDispatch } from '../../../../redux/store';
import { userActions } from '../../../../redux/user/user.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

const StyledButton = styled(Button)`
  width: 15rem;
  border-radius: 0.5rem;
  font-size: 20px;
  color: black;
  background-color: ${({ theme }) => theme.lightgray};
`;

type SignupEmailProps = {
  updateStep: VoidFunction;
};

export const SignupEmail: React.FC<SignupEmailProps> = ({ updateStep }) => {
  const { t } = useTranslation();

  const [signupLoading, setSignupLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { control, handleSubmit, getValues } = useForm<SignupDto>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      phone: '',
      company: '',
      website: '',
    },
  });

  const onNext = async () => {
    try {
      setSignupLoading(true);
      await dispatch(userActions.signup(getValues())).unwrap();
      AtiraToast.success(t('signup.verification_sent_message'));
      updateStep();
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setSignupLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        padding="m"
        gap="m"
      >
        <Controller
          name="name"
          rules={{
            required: true,
            minLength: {
              value: 3,
              message: t('signup.error.user_name.short_message'),
            },
            maxLength: {
              value: 18,
              message: t('signup.error.user_name.long_message'),
            },
            pattern:
              /^(?![-._\s])[a-zA-Z0-9][-._a-zA-Z0-9\s]{1,18}(?<![-._\s])$/,
          }}
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.name')}
              type="name"
              value={value}
              onChange={onChange}
              id="name"
              valid={isEmpty(errors.name)}
              errorMessage={errors.name?.message || ''}
              required
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/,
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.email')}
              type="email"
              value={value}
              onChange={onChange}
              id="email"
              valid={isEmpty(errors.email)}
              errorMessage={errors.email?.message || t('signup.error.email')}
              required
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.password')}
              type="password"
              value={value}
              onChange={onChange}
              id="password"
              valid={isEmpty(errors.password)}
              required
              errorMessage={
                errors.password?.message || t('signup.error.password')
              }
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.phone')}
              type="phone"
              value={value}
              onChange={onChange}
              id="phone"
              valid={isEmpty(errors.phone)}
            />
          )}
        />

        <Controller
          name="company"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.company')}
              type="Company"
              value={value}
              onChange={onChange}
              id="Company"
              valid={isEmpty(errors.company)}
            />
          )}
        />

        <Controller
          name="website"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.website')}
              type="website"
              value={value}
              onChange={onChange}
              id="website"
            />
          )}
        />
        <StyledButton
          loading={signupLoading}
          onClick={handleSubmit(onNext, (e) => {
            console.log('invalid form submission', e);
          })}
        >
          {t('common.signup')}
        </StyledButton>
      </Flex>
    </Flex>
  );
};
