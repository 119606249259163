import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faArrowsUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../AtiraIcon';
import { Flex } from '../Flex';

const StyledContainer = styled(Flex)`
  width: 100%;
  position: relative;
`;

const StyledDraggable = styled.div`
  position: absolute;
  top: 3.3rem;
  right: 0.5rem;
  &:hover {
    cursor: grab;
  }
`;

type SortableChildProps = {
  id: string;
  children?: React.ReactNode;
  showDragIcon?: boolean;
};

export const SortableChild: React.FC<SortableChildProps> = ({
  id,
  children,
  showDragIcon = false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  if (showDragIcon) {
    return (
      <StyledContainer ref={setNodeRef} style={style}>
        <Flex flex={1} width={'100%'}>
          {children}
        </Flex>
        <StyledDraggable {...attributes} {...listeners}>
          <AtiraIcon color="main" icon={faArrowsUpDownLeftRight} />
        </StyledDraggable>
      </StyledContainer>
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};
