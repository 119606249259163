/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Flex } from '../../../../components/Flex';
import { ForgotPasswordEmailDto } from '../../../../model/user/dto/ForgotPasswordEmailDto';
import { ForgotPasswordNewPasswordDto } from '../../../../model/user/dto/ForgotPasswordNewPasswordDto';
import { ForgotPasswordTokenDto } from '../../../../model/user/dto/ForgotPasswordTokenDto';
import { ForgotPasswordEmail } from './ForgotPasswordEmail';
import { ForgotPasswordNewPassword } from './ForgotPasswordNewPassword';
import { ForgotPasswordToken } from './ForgotPasswordToken';

enum FORGOT_PASS_STEPS {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
  NEW = 'NEW_PASSWORD',
}

export const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState<FORGOT_PASS_STEPS>(FORGOT_PASS_STEPS.EMAIL);
  const formMethods = useForm<
    ForgotPasswordEmailDto &
      ForgotPasswordTokenDto &
      ForgotPasswordNewPasswordDto
  >({
    defaultValues: { email: '', token: '', newPassword: '' },
  });

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      <FormProvider {...formMethods}>
        {step === FORGOT_PASS_STEPS.EMAIL ? (
          <ForgotPasswordEmail
            updateStep={() => setStep(FORGOT_PASS_STEPS.TOKEN)}
          />
        ) : step === FORGOT_PASS_STEPS.TOKEN ? (
          <ForgotPasswordToken
            updateStep={() => setStep(FORGOT_PASS_STEPS.NEW)}
          />
        ) : (
          <ForgotPasswordNewPassword />
        )}
      </FormProvider>
    </Flex>
  );
};
