import { createSlice } from '@reduxjs/toolkit';

import { EntryAxios } from '../../axios/entry/entry.axios';
import { EntriesTableSettings } from '../../model/entries-table-settings/EntriesTableSettings';
import { GetUserEntriesTableSettingsDto } from '../../model/entries-table-settings/dto/GetUserEntriesTableSettingsDto';
import { UpdateUserEntriesTableSettingsDto } from '../../model/entries-table-settings/dto/UpdateUserEntriesTableSettingsDto';
import { Entry } from '../../model/entry/Entry';
import { ConvertEntryBulkDto } from '../../model/entry/dto/ConvertEntryBulkDto';
import { CreateEntryDto } from '../../model/entry/dto/CreateNewEntryDto';
import { CreateTaskDto } from '../../model/entry/dto/CreateTaskDto';
import { DeleteEntryBulkDto } from '../../model/entry/dto/DeleteEntryBulkDto';
import { DeleteEntryDto } from '../../model/entry/dto/DeleteEntryDto';
import { GetEntryByIdDto } from '../../model/entry/dto/GetEntryByIdDto';
import { GetEntryLegibleForKanbanCardDto } from '../../model/entry/dto/GetEntryLegibleForKanbanCardDto';
import { GetLatestEntriesDto } from '../../model/entry/dto/GetLatestEntriesDto';
import { SearchEntryDto } from '../../model/entry/dto/SearchEntryDto';
import { UpdateEntryDto } from '../../model/entry/dto/UpdateEntryDto';
import { UpdateTaskDto } from '../../model/entry/dto/UpdateTaskDto';
import { PageDto } from '../../model/meta/PageDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface EntryReducer {
  latestEntries: Entry[];
  latestEntriesLoading: boolean;
  latestEntriesMeta: PageDto;
  //
  entryTableSettings?: EntriesTableSettings;
  //
  searchEntries: Entry[];
  searchEntriesLoading: boolean;
  //
  entiresLegibleForKanbanCard: Entry[];
}

const initialState = Object.freeze<EntryReducer>({
  latestEntries: [],
  latestEntriesLoading: false,
  latestEntriesMeta: {
    page: 0,
    count: 10,
  },
  entryTableSettings: undefined,
  searchEntries: [],
  searchEntriesLoading: false,
  entiresLegibleForKanbanCard: [],
});

const getEntryById = AtiraThunk<Entry, GetEntryByIdDto>('/entry/:id', (dto) =>
  EntryAxios.getEntryById(dto),
);

const deleteEntry = AtiraThunk<void, DeleteEntryDto>('/entry/delete', (dto) =>
  EntryAxios.deleteEntry(dto),
);

const updateEntryById = AtiraThunk<void, UpdateEntryDto>(
  '/entry/update',
  (dto) => EntryAxios.updateEntryById(dto),
);
const createNewEntry = AtiraThunk<void, CreateEntryDto>('/form/submit', (dto) =>
  EntryAxios.createNewEntry(dto),
);

// const getEntriesByFormId = AtiraThunk<Entry[], GetEntriesByFormIdDto>(
//   '/entry/:formdId/all',
//   (dto) => EntryAxios.getEntriesByFormId(dto),
// );

const getLatestEntries = AtiraThunk<
  PageResponseDto<Entry[]>,
  GetLatestEntriesDto
>(`/entry/latest`, (dto) => EntryAxios.getLatestEntries(dto));

const deleteEntryBulk = AtiraThunk<void, DeleteEntryBulkDto>(
  '/entry/delete/bulk',
  (dto) => EntryAxios.deleteEntryBulk(dto),
);

const convertEntryBulk = AtiraThunk<void, ConvertEntryBulkDto>(
  '/entry/convert/bulk',
  (dto) => EntryAxios.convertEntryBulk(dto),
);

const getUserTableSettings = AtiraThunk<
  EntriesTableSettings,
  GetUserEntriesTableSettingsDto
>('/entry/table-settings', (dto) => EntryAxios.getUserTableSettings(dto));

const updateUserTableSettings = AtiraThunk<
  void,
  UpdateUserEntriesTableSettingsDto
>(`/entry/table-settings/:id`, (dto) =>
  EntryAxios.updateUserTableSettings(dto),
);

const searchEntries = AtiraThunk<Entry[], SearchEntryDto>(
  `/entry/search`,
  (dto) => EntryAxios.searchEntries(dto),
);

const getEntriesLegibleForKanbanCard = AtiraThunk<
  Entry[],
  GetEntryLegibleForKanbanCardDto
>('/entry/search/kanban-legible', (dto) =>
  EntryAxios.getEntriesLegibleForKanbanCard(dto),
);

const createTask = AtiraThunk<void, CreateTaskDto>(`/task`, (dto) =>
  EntryAxios.createTask(dto),
);

const updateTask = AtiraThunk<void, UpdateTaskDto>('/task/edit', (dto) =>
  EntryAxios.updateTask(dto),
);

const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    emptySearchEntries: (state) => {
      state.searchEntries = [];
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getEntryById.fulfilled, (state, action) => {
      const entries = state.latestEntries;

      const newEntries = entries.map((ent) =>
        ent._id === action.meta.arg.entryId ? action.payload : ent,
      );

      state.latestEntries = newEntries;
    });

    addCase(getLatestEntries.pending, (state, action) => {
      state.latestEntriesLoading = true;
    });
    addCase(getLatestEntries.fulfilled, (state, action) => {
      state.latestEntriesMeta = action.payload.meta;
      state.latestEntriesLoading = false;
      if (action.payload.meta.page === 1) {
        state.latestEntries = action.payload.data || [];
      } else {
        state.latestEntries = [
          ...state.latestEntries,
          ...(action.payload.data || []),
        ];
      }
    });
    addCase(getLatestEntries.rejected, (state, action) => {
      state.latestEntriesLoading = false;
    });

    addCase(getUserTableSettings.fulfilled, (state, action) => {
      state.entryTableSettings = action.payload;
    });

    addCase(searchEntries.pending, (state, action) => {
      state.searchEntriesLoading = true;
    });
    addCase(searchEntries.fulfilled, (state, action) => {
      state.searchEntriesLoading = false;
      state.searchEntries = action.payload;
    });
    addCase(searchEntries.rejected, (state, action) => {
      state.searchEntriesLoading = false;
    });

    addCase(getEntriesLegibleForKanbanCard.fulfilled, (state, action) => {
      state.entiresLegibleForKanbanCard = action.payload;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      state.latestEntries = [];
    });
  },
});

export const entryActions = {
  ...entrySlice.actions,
  deleteEntry,
  getEntryById,
  updateEntryById,
  createNewEntry,
  getLatestEntries,
  deleteEntryBulk,
  convertEntryBulk,
  getUserTableSettings,
  updateUserTableSettings,
  createTask,
  searchEntries,
  getEntriesLegibleForKanbanCard,
  updateTask,
};

export default entrySlice.reducer;
