import { User } from '../../model/user/User';
import { CodeVerifyDto } from '../../model/user/dto/CodeVerifyDto';
import { ForgotPasswordEmailDto } from '../../model/user/dto/ForgotPasswordEmailDto';
import { ForgotPasswordNewPasswordDto } from '../../model/user/dto/ForgotPasswordNewPasswordDto';
import { ForgotPasswordTokenDto } from '../../model/user/dto/ForgotPasswordTokenDto';
import { LoginDto } from '../../model/user/dto/LoginDto';
import { LoginResponseDto } from '../../model/user/dto/LoginResponseDto';
import { SignupDto } from '../../model/user/dto/SignupDto';
import { SignupSuccessDto } from '../../model/user/dto/SignupSuccessDto';
import { UpdateUserDto } from '../../model/user/dto/UpdateUserDto';
import { AtiraApi } from '../AxiosClient';

export class UserAxios {
  static login(dto: LoginDto) {
    return AtiraApi.post<LoginResponseDto>(`/auth/login`, dto).then(
      ({ data }) => data,
    );
  }

  static forgotPasswordEmail(dto: ForgotPasswordEmailDto) {
    return AtiraApi.post<void | string>(
      '/auth/forgot-password/email',
      dto,
    ).then(({ data }) => data);
  }

  static forgotPasswordToken(dto: ForgotPasswordTokenDto) {
    return AtiraApi.post<void>('/auth/forgot-password/token', dto).then(
      ({ data }) => data,
    );
  }

  static forgotPasswordNewPassword(dto: ForgotPasswordNewPasswordDto) {
    return AtiraApi.post<void>('/auth/forgot-password/new', dto).then(
      ({ data }) => data,
    );
  }

  static signup(dto: SignupDto) {
    return AtiraApi.post(`/auth/signup`, dto).then(({ data }) => data);
  }

  static verifyUserAccount(dto: CodeVerifyDto) {
    return AtiraApi.post<SignupSuccessDto>(`/auth/code-verify`, dto).then(
      ({ data }) => data,
    );
  }

  static loginWithGoogle(dto: { code: string }) {
    return AtiraApi.post<SignupSuccessDto>(`/auth/google`, dto).then(
      ({ data }) => data,
    );
  }

  static getLoggedInUser(userId: string) {
    return AtiraApi.get<User>(`/user/${userId}`).then(({ data }) => data);
  }

  static refreshToken() {
    return AtiraApi.post(`/auth/refresh-token`).then(({ data }) => data);
  }

  static createLoginWithGoogleLink() {
    return AtiraApi.get(`/auth/create-google-auth-link`).then(
      ({ data }) => data,
    );
  }

  static updateUserInfo(dto: UpdateUserDto) {
    return AtiraApi.patch(`/user/${dto.userId}`, dto).then(({ data }) => data);
  }
}
