import styled from 'styled-components';

import { Images } from '../assets';
import { AtiraImage, AtiraImageProps } from './Image';

const StyledImage = styled(AtiraImage)`
  filter: grayscale(100%);
  opacity: 0.4;
`;

type AtiraSpaceGreyIconProps = Omit<AtiraImageProps, 'src'>;

export const AtiraSpaceGreyIcon: React.FC<AtiraSpaceGreyIconProps> = (
  props,
) => {
  return (
    <StyledImage
      src={Images.AtiraGrupIcon}
      width={props.width || '5rem'}
      {...props}
    />
  );
};
