import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';

type EntryConvertBulkModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: ATVoidFunction<EntryKind>;
  loading: boolean;
};

export const EntryConvertBulkModal: React.FC<EntryConvertBulkModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
}) => {
  const [entryKind, setEntryKind] = useState<EntryKind | undefined>(undefined);

  const { t } = useTranslation(undefined, { keyPrefix: 'entries.table' });

  const getDropDownOptions = () => {
    return Object.keys(EntryKind).map((x) => ({
      label: t(`entry.${x}`),
      value: x,
    }));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      content={{ height: '25rem', maxHeight: '25rem', overflow: 'scroll' }}
    >
      <Flex
        gap="m"
        paddingLeft="xl"
        paddingRight="xl"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontSize="xxl" align="center" color="darkTextColor">
          {t('entry.convert.bulk.title')}
        </Text>
        <Flex flexDirection="column" gap="m">
          <Text color="darkTextColor">{t('entry.convert.bulk.subtitle')}</Text>
          <DropDown
            options={getDropDownOptions()}
            value={{
              label: t(`entry.${entryKind || EntryKind.LEAD}`),
              value: entryKind,
            }}
            onChange={(e: any) => setEntryKind(e)}
          />
          <Button
            loading={loading}
            disabled={!entryKind}
            onClick={() => onSubmit(entryKind!)}
          >
            {t('entry.convert.update.button')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
