import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import { ForgotPasswordTokenDto } from '../../../../model/user/dto/ForgotPasswordTokenDto';
import { useAppDispatch } from '../../../../redux/store';
import { userActions } from '../../../../redux/user/user.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';
import {
  GetStartedComponents,
  useGetStartedContext,
} from '../../GetStartedContext';

const StyledButton = styled(Button)`
  width: 15rem;
  height: 3rem;
  border-radius: 0.5rem;
  font-size: 20px;
  margin-top: 50%;
  color: black;
  background-color: ${({ theme }) => theme.lightgray};
`;

export const SignupToken: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordTokenDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { onChangePage } = useGetStartedContext();

  const onNext = async () => {
    try {
      setLoading(true);
      const token = getValues('token');

      await dispatch(userActions.verifyUserAccount({ token })).unwrap();
      AtiraToast.success(t('welcome_message'));
      onChangePage(GetStartedComponents.LOGIN);
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={'l'}
    >
      <Text color="black">{t('signup.verification.token')}</Text>
      <Controller
        name="token"
        control={control}
        rules={{ required: true, minLength: 6 }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <VerificationInput
            placeholder={'-'}
            onChange={onChange}
            value={value}
            inputProps={{
              style: {
                border: errors.token ? '1px solid red' : 'none',
              },
            }}
          />
        )}
      />
      <StyledButton onClick={handleSubmit(onNext)} loading={loading}>
        {t('signup.verification.button')}
      </StyledButton>
    </Flex>
  );
};
