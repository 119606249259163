import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { Entry } from '../../../model/entry/Entry';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

function getAvailableKindUpdateOptions(currentKind: EntryKind): EntryKind[] {
  switch (currentKind) {
    case EntryKind.LEAD:
      return [EntryKind.CONTACT, EntryKind.JUNK];
    case EntryKind.CONTACT:
      return [EntryKind.JUNK];
    case EntryKind.JUNK:
      return [EntryKind.CONTACT];
    default:
      return [];
  }
}

type EntryDeleteModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
};

export const EntryConvertModal: React.FC<EntryDeleteModalProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const [entryKindLodaing, setEntryKindLoading] = useState(false);
  const [entryKind, setEntryKind] = useState<EntryKind | undefined>(undefined);

  const { t } = useTranslation(undefined, { keyPrefix: 'entries.table' });
  const dispatch = useAppDispatch();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );

  const getDropDownOptions = () => {
    return getAvailableKindUpdateOptions(entry?.kind as EntryKind)
      .filter((k) => k !== entry?.kind)
      .map((x) => ({
        label: t(`entry.${x}`),
        value: x,
      }));
  };

  const updateEntryKind = async () => {
    try {
      setEntryKindLoading(true);
      await dispatch(
        entryActions.updateEntryById({
          userId: loggedInUserId!,
          kind: entryKind,
          entryId: entry!._id,
        }),
      ).unwrap();
      await dispatch(
        entryActions.getEntryById({
          entryId: entry!._id,
          userId: loggedInUserId!,
        }),
      ).unwrap();
      AtiraToast.success('Entry Updated');
      onClose();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setEntryKindLoading(false);
    }
  };

  if (!entry) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      content={{ height: '25rem', maxHeight: '25rem', overflow: 'scroll' }}
    >
      <Flex
        gap="m"
        paddingLeft="xl"
        paddingRight="xl"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontSize="xxl" align="center" color="darkTextColor">
          {t('entry.convert.title')}
        </Text>
        <Flex flexDirection="column" gap="m">
          <Text color="darkTextColor">
            {t('entry.convert.subtitle', {
              kind: t(`entry.${entry?.kind}`),
            })}
          </Text>
          <DropDown
            options={getDropDownOptions()}
            value={{
              label: t(`entry.${entryKind || entry?.kind}`),
              value: entryKind,
            }}
            onChange={(e: any) => setEntryKind(e)}
          />
          <Button
            width="15rem"
            height="2.5rem"
            loading={entryKindLodaing}
            onClick={updateEntryKind}
          >
            {t('entry.convert.update.button')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
