import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAR from './locales/ar.json';
import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';

export enum AppLangs {
  AR = 'ar',
  EN = 'en',
  TR = 'tr',
}

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  tr: {
    translation: translationTR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('lang') || 'en',
  fallbackLng: 'ar',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
