import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { MenuProps, Tooltip } from 'antd';
import Dropdown from 'antd/es/dropdown/dropdown';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Form } from '../../../model/form/Form';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { formActions } from '../../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Spacing } from '../../../theme/Spacing';

const FormContainer = styled(Flex)`
  padding: 0 ${Spacing.m};
  background-color: ${(props) => props.theme.white};
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  padding: 0;
`;

const StyledActionButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  display: flex;
  padding: 0;
  margin: ${Spacing.s} 0;
  width: 100%;
`;

const getMenuItems = (
  t: TFunction,
  currentForm: Form | null,
  openDeleteFormModal: ATVoidFunction<Form>,
): MenuProps['items'] => [
  {
    key: 'g1',
    type: 'group',
    children: [
      {
        key: '1',
        label: (
          <StyledActionButton onClick={() => openDeleteFormModal(currentForm!)}>
            <Flex gap="s" alignItems="center">
              <AtiraIcon icon={faTrash} color="main" />
              <Text color="main">{t('common.delete')}</Text>
            </Flex>
          </StyledActionButton>
        ),
      },
    ],
  },
];

type UserFormProps = {
  form: Form;
  hoveredFormId: string | null;
  onHover: ATVoidFunction<string | null>;
  onShare: ATVoidFunction<Form>;
  onPreview: ATVoidFunction<Form>;
  onDelete: ATVoidFunction<Form>;
  onNavigateToEntries: ATVoidFunction<Form>;
  onCreate: ATVoidFunction<Form>;
};

export const UserForm: React.FC<UserFormProps> = ({
  form,
  onHover,
  hoveredFormId,
  onShare,
  onDelete,
  onPreview,
  onNavigateToEntries,
  onCreate,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isHovered = form._id === hoveredFormId;

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const items = useMemo(() => {
    const invokeOnDelete = () => {
      onDelete(form);
    };

    return getMenuItems(t, currentForm, invokeOnDelete);
  }, [currentForm, form, onDelete, t]);

  return (
    <FormContainer
      width="100%"
      alignItems="center"
      onMouseEnter={() => onHover(form._id)}
      onMouseLeave={() => onHover(null)}
    >
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Flex gap="m" alignItems="center">
          <AtiraIcon icon={faFileAlt} size="3x" color="main" />
          <Text fontSize="l">{form.title}</Text>
          <Flex justifyContent="center" alignItems="center" gap="m">
            <AtiraIcon icon={faCircle} size="2xs" color="main" />
            <Text fontSize="l" color="gray">
              {form.entries} Entries
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" gap="m">
          <Flex
            alignItems="center"
            gap="l"
            opacity={hoveredFormId === form._id ? 1 : 0}
            style={{ transition: 'all 0.3s' }}
          >
            <Tooltip title={t('common.entries')}>
              <StyledButton
                iconWidth="2x"
                onClick={() => onNavigateToEntries(form)}
                icon={faTags}
                color="main"
              />
            </Tooltip>

            <Tooltip title={t('forms.form.new_entry')}>
              <StyledButton
                iconWidth="2x"
                onClick={() => onCreate(form)}
                icon={faPlus}
                color="main"
              ></StyledButton>
            </Tooltip>

            <Tooltip title={t('common.preview')}>
              <StyledButton
                iconWidth="2x"
                onClick={() => onPreview(form)}
                icon={faEye}
                color="main"
              />
            </Tooltip>

            <Tooltip title={t('common.share')}>
              <StyledButton
                iconWidth="2x"
                icon={faShare}
                onClick={() => onShare(form)}
                color="main"
              />
            </Tooltip>
          </Flex>

          <Dropdown
            menu={{ items }}
            placement="bottom"
            arrow={{ pointAtCenter: true }}
            trigger={['click']}
          >
            <Tooltip title={t('common.actions')}>
              <Button
                icon={faEllipsis}
                color="black"
                iconWidth="2x"
                backgroundColor="transparent"
                onClick={() => dispatch(formActions.setCurrentForm(form))}
              />
            </Tooltip>
          </Dropdown>
        </Flex>
      </Flex>
    </FormContainer>
  );
};
