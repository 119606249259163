import { Drawer } from 'antd';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { DropDownRemoteSearch } from '../../../components/DropDownRemoteSearch';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import i18n, { AppLangs } from '../../../i18n';
import { Entry } from '../../../model/entry/Entry';
import { KanbanColumn } from '../../../model/kanban/KanbanColumn';
import { CreateKanbanCardDto } from '../../../model/kanban/dto/CreateKanbanCardDto';
import { entrySliceSelectors } from '../../../redux/entry/entry.selector';
import { entryActions } from '../../../redux/entry/entry.slice';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledDropdown = styled(DropDown)`
  height: 2.3rem;
`;

const StyledSearchDropDown = styled(DropDownRemoteSearch)`
  height: 2.3rem;
`;

const StyledInput = styled(Input)`
  height: 2.3rem;
  border-radius: 0.3rem;

  &::placeholder {
    font-size: 1.5rem;
  }
`;

const AmountCurrency = styled.span`
  position: absolute;
  right: 0.5rem;
  bottom: 12%;
  font-size: 1.2rem;
`;

const SubmitButton = styled(Button)`
  height: 2.3rem;
  border-radius: 0.3rem;
  width: 50%;
  margin-left: auto;
`;

type KanbanCardDrawerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  kanbanColumn?: KanbanColumn | null;
  entry?: Entry | null;
};

export const KanbanCreateCardDrawer: React.FC<KanbanCardDrawerProps> = ({
  isOpen = false,
  onClose,
  kanbanColumn,
  entry,
}) => {
  const [createKanbanCardLoading, setCreateKanbanCardLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const drawerPlacement = i18n.language === AppLangs.AR ? 'left' : 'right';

  const userKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );
  const legibleEntries = useAppSelector(
    entrySliceSelectors.selectEntriesLegibleForKanbanCard,
  );

  const { control, handleSubmit, getValues, setValue, reset, watch } =
    useForm<CreateKanbanCardDto>({
      defaultValues: { kanbanId: userKanban?._id },
    });

  const values = watch();

  const onCreateKanbanCrad = async () => {
    try {
      setCreateKanbanCardLoading(true);

      const dto = getValues();

      const payload = omitBy(
        {
          userId: loggedInUserId!,
          entryId: dto.entryId,
          kanbanId: userKanban?._id!,
          columnId: dto.columnId,
          order: 0,
          title: dto.title,
          closingDate: dto.closingDate
            ? new Date(dto.closingDate).getTime()
            : null,
          amount: dto.amount,
          note: dto.note,
        },
        isNil,
      ) as unknown as CreateKanbanCardDto;

      await dispatch(kanbanActions.createKanbanCard(payload)).unwrap();
      await dispatch(
        kanbanActions.getUserDefaultKanban({ userId: loggedInUserId! }),
      ).unwrap();

      localOnClose();
      AtiraToast.success(t('deals.drawer.create.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setCreateKanbanCardLoading(false);
    }
  };

  const localOnClose = () => {
    onClose();
    reset();
  };

  const onFetchEntries = async (keyword: string) => {
    try {
      const entries = await dispatch(
        entryActions.getEntriesLegibleForKanbanCard({
          keyword,
          userId,
        }),
      ).unwrap();

      return transformEntries(entries || []);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
      return [];
    }
  };

  const transformEntries = (entries: Entry[]) =>
    entries.map((entry) => ({
      key: entry._id,
      label:
        entry.fields.name || entry.fields.email || entry.fields?.message || '',
      value: entry._id,
    }));

  useEffect(() => {
    if (isOpen) {
      dispatch(entryActions.getEntriesLegibleForKanbanCard({ userId }));
    }
  }, [dispatch, entry, isOpen, userId]);

  useEffect(() => {
    if (entry && isOpen) {
      setValue('entryId', entry._id);
    }
  }, [entry, isOpen, setValue]);

  return (
    <Drawer
      destroyOnClose
      title={t('deals.drawer.create.title')}
      open={isOpen}
      onClose={localOnClose}
      placement={drawerPlacement}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('deals.drawer.input.deal_name')}
            />
          )}
        />

        <StyledSearchDropDown
          fetchOptions={onFetchEntries}
          onChange={(e: any) => setValue('entryId', e.value)}
          title={t('deals.drawer.input.contact_name')}
          placeholder={t('deals.drawer.input.contact_name_placeholder')}
          defaultOptions={transformEntries(legibleEntries)}
          defaultValue={
            entry
              ? {
                  label:
                    entry.fields.name ||
                    entry.fields.email ||
                    entry.fields?.message ||
                    '',
                  value: entry._id,
                }
              : undefined
          }
        />

        <Controller
          name="columnId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledDropdown
              defaultValue={{
                label: kanbanColumn?.name,
                value: kanbanColumn?._id,
              }}
              value={value}
              options={userKanban?.columns?.map(({ _id, name }) => ({
                label: name,
                value: _id,
              }))}
              onChange={onChange}
              title={t('common.stage')}
            />
          )}
        />

        <Flex position="relative">
          <Controller
            name="amount"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                title={t('common.amount')}
              />
            )}
          />
          <AmountCurrency>$</AmountCurrency>
        </Flex>

        <Controller
          name="closingDate"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <StyledInput
                onChange={(e) => onChange(e)}
                title={t('deals.drawer.input.closing_date')}
                type="date"
                value={
                  value
                    ? new Date(value).toISOString().substring(0, 10)
                    : undefined
                }
              />
            );
          }}
        />

        <Controller
          name="note"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('common.description')}
              type="text"
            />
          )}
        />

        <SubmitButton
          onClick={handleSubmit(onCreateKanbanCrad)}
          loading={createKanbanCardLoading}
          disabled={
            !values.title || !(values as any).entryId || !values.columnId
          }
        >
          {t('common.create')}
        </SubmitButton>
      </Flex>
    </Drawer>
  );
};
