import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons/faArrowRotateRight';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { Tooltip } from 'antd';
import { debounce } from 'lodash';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { GetLatestEntriesFilterDto } from '../../../model/entry/dto/GetLatestEntriesFilterDto';
import { SearchEntryDto } from '../../../model/entry/dto/SearchEntryDto';
import { EntryFilterRange } from '../../../model/entry/types/EntryFilterRange.enum';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { Form } from '../../../model/form/Form';
import { entrySliceSelectors } from '../../../redux/entry/entry.selector';
import { entryActions } from '../../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { EntryTableSettingsDrawer } from './EntryTableSettingsDrawer';

const StyledIconButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  margin: 0;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  height: 3rem;
`;

const StyledDropDown = styled(DropDown)`
  flex: 1;
  min-width: 10rem;
  max-width: 10rem;
`;

export const EntryTableHeader: React.FC = () => {
  const [tableSettingsModalVisible, setTableSettingsModalVisible] =
    useState(false);

  const { control, getValues, setValue } = useFormContext<
    GetLatestEntriesFilterDto | SearchEntryDto
  >();

  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onFetchEntries = () =>
    dispatch(
      entryActions.getLatestEntries({
        userId,
        count: 10,
        page: 0,
        ...omitBy(getValues(), isNil),
      }),
    ).unwrap();

  const onFilter = async () => {
    try {
      await onFetchEntries();
    } catch (e: any) {
      AtiraToast.apiError(e);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async () => {
      try {
        const keyword = getValues('keyword');
        if (keyword) {
          await dispatch(
            entryActions.searchEntries({
              keyword: getValues('keyword'),
              userId,
            }),
          ).unwrap();
        }
      } catch (e: any) {
        AtiraToast.apiError(e);
        console.log(e);
      }
    }, 1000),
    [dispatch, getValues, userId],
  );

  return (
    <Wrapper>
      <Flex gap="m" alignItems="center">
        <StyledIconButton
          backgroundColor="gray"
          onClick={onFetchEntries}
          disabled={entriesLoading}
        >
          <Tooltip title={t('common.refresh')}>
            <AtiraIcon icon={faArrowRotateRight} color="main" size="2x" />
          </Tooltip>
        </StyledIconButton>

        <Input
          borderRadius="0.5rem"
          width="10rem"
          height="2rem"
          placeholder={t('common.search')}
          onChange={({ target }) => {
            setValue('keyword', target.value);
            debouncedSearch();
          }}
        />

        <Controller
          control={control}
          name="kind"
          render={({ field: { onChange, value } }) => (
            <StyledDropDown
              options={[
                {
                  label: capitalize(t('entries.table.entry.All')),
                  value: null,
                },
                {
                  label: capitalize(t('entries.table.entry.CONTACT')),
                  value: EntryKind.CONTACT,
                },
                {
                  label: capitalize(t('entries.table.entry.LEAD')),
                  value: EntryKind.LEAD,
                },
                {
                  label: capitalize(t('entries.table.entry.JUNK')),
                  value: EntryKind.JUNK,
                },
              ]}
              value={{
                label: !value ? t('common.all') : t(value),
                value: value || t('common.all'),
              }}
              onChange={(e) => {
                onChange(e);
                onFilter();
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="formId"
          render={({ field: { onChange, value } }) => (
            <StyledDropDown
              options={[
                { title: t('common.all'), _id: null } as unknown as Form,
                ...forms,
              ].map((f: Form) => ({
                label: f.title,
                value: f._id,
              }))}
              value={{
                label:
                  forms.find(({ _id }) => _id === value)?.title ||
                  t('common.all'),
                value,
              }}
              onChange={(e) => {
                onChange(e);
                onFilter();
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="range"
          render={({ field: { onChange, value } }) => (
            <StyledDropDown
              options={[
                {
                  label: t('common.all'),
                  value: null,
                },
                {
                  label: t('entries.table.entry.filers.time.today'),
                  value: EntryFilterRange.TODAY,
                },
                {
                  label: t('entries.table.entry.filers.time.yesterday'),
                  value: EntryFilterRange.YESTERDAY,
                },
                {
                  label: t('entries.table.entry.filers.time.last_week'),
                  value: EntryFilterRange.LAST_WEEK,
                },
                {
                  label: t('entries.table.entry.filers.time.last_month'),
                  value: EntryFilterRange.LAST_MONTH,
                },
              ]}
              value={{
                label: !value
                  ? t('common.all')
                  : t(`entries.table.entry.filers.time.${value.toLowerCase()}`),
                value: value || 'all',
              }}
              onChange={(e) => {
                onChange(e);
                onFilter();
              }}
            />
          )}
        />
      </Flex>

      <StyledIconButton onClick={() => setTableSettingsModalVisible(true)}>
        <Flex alignItems="center" gap="s">
          <Tooltip title={t('common.settings')}>
            <AtiraIcon icon={faGear} color="main" size="2x" />
          </Tooltip>
          <Text color="main" fontSize="m">
            {t('common.settings')}
          </Text>
        </Flex>
      </StyledIconButton>

      <EntryTableSettingsDrawer
        open={tableSettingsModalVisible}
        onClose={() => setTableSettingsModalVisible(false)}
      />
    </Wrapper>
  );
};
