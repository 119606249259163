import { createSlice } from '@reduxjs/toolkit';

import { NerveAxios } from '../../axios/nerve/nerve.axios';
import { AtiraNerve } from '../../model/nerve/Nerve';
import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';
import { GetUserNervesResponseDto } from '../../model/nerve/dto/GetUserNervesResponseDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';
import { DeleteNerveDto } from './dto/DeleteNerveDto';
import { GenericDisableEnableNerveDto } from './dto/GenericDisableEnableNerveDto';

interface NerveReducer {
  nerves?: AtiraNerve[];
}

const initialState = Object.freeze<NerveReducer>({
  nerves: [],
});

const createUserNeve = AtiraThunk<void, CreateNerveDto>(
  `/nerve/create`,
  (dto) => NerveAxios.createUserNeve(dto),
);

const getUserNerves = AtiraThunk<GetUserNervesResponseDto, GenericWithUserId>(
  `/nerve/get`,
  (dto) => NerveAxios.getUserNerves(dto),
);

const deleteUserNerve = AtiraThunk<void, DeleteNerveDto>(
  `/nerve/delete`,
  (dto) => NerveAxios.deleteNerve(dto),
);

const disableUserNerve = AtiraThunk<void, GenericDisableEnableNerveDto>(
  `/nerve/disable`,
  (dto) => NerveAxios.disableNerve(dto),
);

const enableUserNerve = AtiraThunk<void, GenericDisableEnableNerveDto>(
  `/nerve/enable`,
  (dto) => NerveAxios.enableNerve(dto),
);

const NerveSlice = createSlice({
  name: 'nervesSlice',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getUserNerves.fulfilled, (state, action) => {
      state.nerves = action.payload.nerves;
    });

    addCase(userActions.logout.fulfilled, (state: NerveReducer) => {
      return (state = initialState);
    });
  },
});

export const NerveActions = {
  createUserNeve,
  getUserNerves,
  deleteUserNerve,
  disableUserNerve,
  enableUserNerve,
};

export default NerveSlice.reducer;
