import { createBrowserRouter } from 'react-router-dom';

import { AtiraRoute } from '../AtiraRoute';
import { PrivateRouter } from './Private.router';
import { PublicRouter } from './Public.router';

const router = createBrowserRouter([
  ...PublicRouter,
  ...PrivateRouter,
  { path: '*', element: <AtiraRoute redirect="/" /> },
]);

export default router;
