import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';
import { GenericWithUserId } from '../../model/shared/GenericWithUserId';
import { DeleteNerveDto } from '../../redux/nerve/dto/DeleteNerveDto';
import { GenericDisableEnableNerveDto } from '../../redux/nerve/dto/GenericDisableEnableNerveDto';
import { AtiraApi } from '../AxiosClient';

export class NerveAxios {
  static async createUserNeve(dto: CreateNerveDto) {
    return AtiraApi.post('/nerve/create', dto).then(({ data }) => data);
  }

  static async getUserNerves(dto: GenericWithUserId) {
    return AtiraApi.post(`/nerve/get`, dto).then(({ data }) => data);
  }

  static async deleteNerve(dto: DeleteNerveDto) {
    return AtiraApi.post(`/nerve/delete`, dto).then(({ data }) => data);
  }
  static async disableNerve(dto: GenericDisableEnableNerveDto) {
    return AtiraApi.post(`/nerve/disable`, dto).then(({ data }) => data);
  }
  static async enableNerve(dto: GenericDisableEnableNerveDto) {
    return AtiraApi.post(`/nerve/enable`, dto).then(({ data }) => data);
  }
}
