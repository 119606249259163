import { Radio } from 'antd';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../assets';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { formActions } from '../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';

function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

const FormSubmit = styled(Button)`
  width: 10rem;
  height: 3.3rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  padding: 0;
  z-index: 5;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
`;

export const FormCreateStepTheme: React.FC = () => {
  const [formCreateloading, setFormCreateLoading] = useState(false);

  const { t } = useTranslation();

  const { handleSubmit, getValues, reset, control } =
    useFormContext<CreateFormDto>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loggedinUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const onSubmit = async () => {
    try {
      setFormCreateLoading(true);

      const dto = getValues();

      const checkedInputs = dto.inputs
        ?.filter((x) => x.required)
        ?.map((x, i) => ({ ...x, order: i }))!;

      const payload = { ...dto, inputs: checkedInputs };

      if (!payload.title.length) {
        return AtiraToast.error(t('forms.form.create.error.missing_title'));
      }

      if (!payload.inputs.length) {
        return AtiraToast.error(
          t('forms.form.create.error.missing_at_least_one_input'),
        );
      }

      if (!payload.domains.length) {
        return AtiraToast.error(
          t('forms.form.create.error.missing_at_least_one_domain'),
        );
      }

      const domains = payload.domains
        .filter((domian) => domian.content.length)
        .map((domain) => {
          let url = isValidUrl(domain.content)
            ? new URL(
                domain.content.startsWith('http')
                  ? domain.content
                  : `https://${domain.content}`,
              ).hostname.replace('www.', '')
            : domain.content;

          const regex = url.match(/[a-z]+\./)?.[0]!;

          if (url.split('.').length - 1 === 2) {
            url = url.replace(regex, '');
          }
          return url;
        });

      payload.domains = domains as any;

      await dispatch(
        formActions.createForm({
          ...payload,
          userId: loggedinUserId!,
        }),
      ).unwrap();

      await dispatch(
        formActions.getMyForms({ userId: loggedinUserId }),
      ).unwrap();

      reset();

      AtiraToast.success(t('forms.form.create.success'));

      navigate('/forms');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setFormCreateLoading(false);
    }
  };

  return (
    <Flex flex={1} flexDirection="column" alignItems="center" gap="xl">
      <Flex gap="m">
        <Controller
          control={control}
          name="theme"
          render={({ field: { onChange, value } }) => (
            <StyledRadioGroup onChange={onChange} value={value}>
              <Flex
                flexDirection="column"
                gap="m"
                justifyContent="center"
                alignItems="center"
              >
                <AtiraImage preview src={Images.FormTheme1} height="20rem" />
                <Radio value={FormTheme.white} defaultChecked>
                  <Text>White Theme</Text>
                </Radio>
              </Flex>

              <Flex
                flexDirection="column"
                gap="m"
                justifyContent="center"
                alignItems="center"
              >
                <AtiraImage preview src={Images.FormTheme2} height="20rem" />
                <Radio value={FormTheme.purple}>
                  <Text>Purple Theme</Text>
                </Radio>
              </Flex>

              <Flex
                flexDirection="column"
                gap="m"
                justifyContent="center"
                alignItems="center"
              >
                <AtiraImage preview src={Images.FormTheme3} height="20rem" />
                <Radio value={FormTheme.purple_curve}>
                  <Text>Curved Purple Theme</Text>
                </Radio>
              </Flex>
            </StyledRadioGroup>
          )}
        />
      </Flex>
      <Flex justifyContent="center" width={'100%'}>
        <FormSubmit
          onClick={handleSubmit(onSubmit)}
          loading={formCreateloading}
        >
          {t('forms.create_form')}
        </FormSubmit>
      </Flex>
    </Flex>
  );
};
