import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { UpdateEntryDto } from '../../../model/entry/dto/UpdateEntryDto';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { standardDate } from '../../../utils/Date';

type EntryDetailsModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
};

const CustomDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-size: 2rem;
  }
`;

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  border-radius: 0.3rem;
  max-height: 7rem;
`;

export const EntryDetailsModal: React.FC<EntryDetailsModalProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, getValues, reset } = useForm<UpdateEntryDto>({
    defaultValues: { description: entry?.description || '' },
  });
  const dispatch = useAppDispatch();

  const [saveNoteLoading, setSaveNoteLoading] = useState(false);

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const entryDate = standardDate(entry?.createdAt || '');

  const onSaveNote = async () => {
    try {
      setSaveNoteLoading(true);
      await dispatch(
        entryActions.updateEntryById({
          entryId: entry!._id,
          userId,
          description: getValues('description'),
        }),
      );
      await dispatch(
        entryActions.getEntryById({ entryId: entry!._id, userId }),
      ).unwrap();
      AtiraToast.success(t('entries.table.detailsModal.textarea.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setSaveNoteLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({ description: entry?.description });
    }
  }, [entry?.description, isOpen, reset]);

  return (
    <CustomDrawer title={t('common.details')} onClose={onClose} open={isOpen}>
      <Flex flexDirection="column" gap="l">
        <Flex flexDirection="column" gap="s">
          <Text fontSize="l" fontWeight={'bold'} color="darkTextColor">
            {t('common.kind')}
          </Text>
          <Text color="darkTextColor">
            {t(`entries.table.entry.${entry?.kind}`)}
          </Text>
        </Flex>

        <Flex flexDirection="column" gap="s">
          <Text fontSize="l" fontWeight={'bold'} color="darkTextColor">
            {t('entries.table.header.form_title')}
          </Text>

          {entry?.parentForm?.title ? (
            <Text color="darkTextColor">{entry?.parentForm.title}</Text>
          ) : null}
        </Flex>

        <Flex flexDirection="column" gap="s">
          <Text fontSize="l" fontWeight={'bold'} color="darkTextColor">
            {t('common.details')}:
          </Text>
          {Object.entries(entry?.fields || {}).map(([key, value]) => (
            <Flex gap="s" key={key}>
              <Text fontSize="m" fontWeight={'bold'} color="darkTextColor">
                {key}:
              </Text>
              <Text>{value}</Text>
            </Flex>
          ))}
        </Flex>

        <Flex flexDirection="column" gap="s">
          <Text fontSize="l" fontWeight={'bold'} color="darkTextColor">
            {t('common.info')}:
          </Text>
          <Flex gap="s">
            <Text fontSize="m" fontWeight={'bold'} color="darkTextColor">
              {t('entries.table.header.submit_time')}:
            </Text>
            <Text color="darkTextColor">{entryDate}</Text>
          </Flex>
        </Flex>
        <hr />
        <Flex flexDirection="column" gap="s">
          <Text fontSize="l" fontWeight={'bold'} color="darkTextColor">
            {t('entries.table.detailsModal.textarea.title', {
              kind: entry?.kind,
            })}
          </Text>
          <StyledTextarea
            placeholder={t('entries.table.detailsModal.textarea.placeholder')}
            rows={3}
            {...register('description')}
          />
          <Button
            loading={saveNoteLoading}
            width="10rem"
            height="2rem"
            borderRadius="0.3rem"
            onClick={handleSubmit(onSaveNote)}
          >
            {t('common.save')}
          </Button>
        </Flex>
      </Flex>
    </CustomDrawer>
  );
};
