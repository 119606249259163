import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { Text } from '../../../../components/Text';
import { ForgotPasswordEmailDto } from '../../../../model/user/dto/ForgotPasswordEmailDto';
import { useAppDispatch } from '../../../../redux/store';
import { userActions } from '../../../../redux/user/user.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';

const StyledButton = styled(Button)`
  width: 15rem;
  height: 3rem;
  border-radius: 0.5rem;
  font-size: 20px;
  margin-top: 50%;
  color: black;
  background-color: ${({ theme }) => theme.lightgray};
`;

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

type ForgotPasswordEmailProps = {
  updateStep: VoidFunction;
};

export const ForgotPasswordEmail: React.FC<ForgotPasswordEmailProps> = ({
  updateStep,
}) => {
  const [resetButtonLoading, setResetButtonLoading] = useState(false);

  const { control, handleSubmit, getValues } =
    useFormContext<ForgotPasswordEmailDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onNext = async () => {
    try {
      setResetButtonLoading(true);
      const response = await dispatch(
        userActions.forgotPasswordEmail({ email: getValues('email') }),
      ).unwrap();
      AtiraToast.success(response || t('resetpassword.email.success'));
      updateStep();
    } catch (e: any) {
      AtiraToast.apiError(e, { autoClose: 6000 });
      console.log(e);
    } finally {
      setResetButtonLoading(false);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={'l'}
    >
      <Text color="black">{t('resetpassword.email.title')}</Text>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <StyledInput
            title={t('common.email')}
            type="email"
            value={value}
            onChange={onChange}
            id="email"
            valid={isEmpty(errors.email)}
            placeholder={t('resetpassword.email.input.placeholder')}
          />
        )}
      />
      <StyledButton onClick={handleSubmit(onNext)} loading={resetButtonLoading}>
        {t('resetpassword.email.button')}
      </StyledButton>
    </Flex>
  );
};
