import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faDiamond } from '@fortawesome/free-solid-svg-icons/faDiamond';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { Dropdown, MenuProps, TableProps, Tooltip } from 'antd';
import { isAfter } from 'date-fns/isAfter';
import { isToday } from 'date-fns/isToday';
import { isTomorrow } from 'date-fns/isTomorrow';
import { TFunction } from 'i18next';
import truncate from 'lodash/truncate';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { EntriesTableSettings } from '../../../model/entries-table-settings/EntriesTableSettings';
import { Entry } from '../../../model/entry/Entry';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';

type FieldFunctions = {
  delete: ATVoidFunction<Entry>;
  convert: ATVoidFunction<Entry>;
  details: ATVoidFunction<Entry>;
  deal: ATVoidFunction<Entry>;
  update: ATVoidFunction<Entry>;
  createTask: ATVoidFunction<Entry>;
  previewTask: ATVoidFunction<Entry>;
  t: TFunction;
};

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

const TRUNCATE_LENGTH = 30;

const getTruncatedCell = (value?: string) => {
  if (value && value.length >= TRUNCATE_LENGTH) {
    return (
      <Tooltip title={value}>
        <Text>{truncate(value, { length: TRUNCATE_LENGTH })}</Text>
      </Tooltip>
    );
  }

  return <Text>{value}</Text>;
};

const getFieldWidth = (field: string) => {
  switch (field) {
    case 'message':
    case 'phone':
    case 'description':
    case 'form':
      return '10rem';

    default:
      return '7rem';
  }
};

const getFieldInfo = (
  column: EntriesTableSettings['columns'][number],
  functions: FieldFunctions,
) => {
  const { field, enabled, order, toggleAble } = column;
  const {
    t,
    delete: deleteFn,
    convert,
    details,
    update,
    deal,
    createTask,
    previewTask,
  } = functions;
  const imgWidth = '20';

  switch (field) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'actions',
        render: (entry: Entry) => {
          const items: MenuProps['items'] = [
            {
              key: 'g1',
              type: 'group',
              children: [
                {
                  key: '1',
                  label: (
                    <StyledActionButton onClick={() => update(entry)}>
                      <Flex gap="s">
                        <AtiraIcon icon={faPenToSquare} color="main" />
                        <Text color="main">{t('common.edit')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <StyledActionButton onClick={() => details(entry)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faEye} color="main" />
                        <Text color="main">{t('common.preview')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  key: '3',
                  label: (
                    <StyledActionButton onClick={() => deal(entry)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faDiamond} color="main" />
                        <Text color="main">{t('common.deal')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                  valid: entry.kind === EntryKind.CONTACT,
                },
                {
                  key: '4',
                  label: (
                    <StyledActionButton onClick={() => convert(entry)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faRotate} color="main" />
                        <Text color="main">{t('common.convert')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  key: '5',
                  label: (
                    <StyledActionButton
                      width={imgWidth}
                      onClick={() => createTask(entry)}
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faCheck} color="main" />
                        <Text color="main">{t('common.task')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                  valid: !entry.task && entry.kind === EntryKind.CONTACT,
                },
                {
                  key: '6',
                  label: (
                    <StyledActionButton
                      width={imgWidth}
                      onClick={() => deleteFn(entry)}
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faTrashCan} color="main" />
                        <Text color="main">{t('common.delete')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
              ]
                .filter((item) => item.valid !== false)
                .map((child) => ({
                  ...child,
                  style: { padding: 4 },
                })),
            },
          ];

          return (
            <Flex justifyContent="center">
              <Dropdown
                menu={{ items }}
                placement="bottomLeft"
                arrow={{ pointAtCenter: true }}
                trigger={['click']}
              >
                <Tooltip title={t('common.menu')}>
                  <Button
                    icon={faEllipsis}
                    backgroundColor="transparent"
                    padding="0"
                    color="black"
                  />
                </Tooltip>
              </Dropdown>
            </Flex>
          );
        },
        reorder: true,
        center: true,
        width: '5rem',
        fixed: 'left',
        data: column,
      };

    case 'task':
      return {
        title: t('common.task'),
        key: 'actions',
        render: (entry: Entry) => {
          if (entry.task) {
            const currentDate = new Date();

            let taskDeadLineDay = 0;
            let taskDeadLineMonth = 0;

            if (entry.task?.deadline) {
              const taskDeadLine = new Date(entry.task?.deadline);
              taskDeadLineMonth = taskDeadLine.getMonth() + 1;
              taskDeadLineDay = taskDeadLine.getDate();
            }

            const isOutDue = () =>
              entry.task?.deadline
                ? isAfter(currentDate, entry.task.deadline)
                : false;

            const getButtonBackgoundColor = () => {
              if (entry.task?.deadline) {
                if (isTomorrow(entry.task.deadline)) {
                  return 'orange';
                } else if (isToday(entry.task.deadline)) {
                  return 'red';
                } else {
                  return 'green';
                }
              } else {
                return 'transparent';
              }
            };

            const getButtonTitle = () => {
              if (entry.task?.deadline) {
                if (isToday(entry.task.deadline)) {
                  return t('common.today');
                } else if (isTomorrow(entry.task.deadline)) {
                  return t('common.tomorrow');
                } else if (isOutDue()) {
                  return t('common.out_due');
                } else {
                  return `${taskDeadLineDay}/${taskDeadLineMonth}`;
                }
              } else {
                return entry?.task?.name;
              }
            };

            return (
              <Button
                color={entry.task.deadline ? 'white' : 'black'}
                width="100%"
                border={entry.task.deadline ? '0' : `1px solid black`}
                onClick={() => previewTask(entry)}
                backgroundColor={getButtonBackgoundColor()}
              >
                {getButtonTitle()}
              </Button>
            );
          } else {
            return null;
          }
        },
        reorder: true,
        center: true,
        width: '5rem',
        fixed: 'left',
        data: column,
      };

    case 'name':
      return {
        title: t('common.name'),
        key: 'name',

        render: (entry: Entry) => {
          return (
            <Button
              onClick={() => details(entry)}
              backgroundColor="transparent"
              margin="0"
              padding="0"
            >
              <Text>
                {getTruncatedCell(
                  entry.fields?.name || t('entries.table.entry.unknown_title'),
                )}
              </Text>
            </Button>
          );
        },
        sorter: (a: Entry, b: Entry) =>
          a.fields?.name.localeCompare(b.fields?.name),
        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(field),
      };

    case 'time':
      return {
        title: t('entries.table.header.submit_time'),
        key: 'time',
        render: ({ createdAt }: Entry) => standardDate(new Date(createdAt)),
        sorter: (a: Entry, b: Entry) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB;
        },
        sortable: true,
        reorder: true,
        width: '9.5rem',
        data: column,
      };

    case 'form':
      return {
        title: t('entries.table.header.form_title'),
        key: 'formTitle',
        render: ({ parentForm }: Entry) =>
          getTruncatedCell(
            parentForm?.title || t('entries.table.entry.unknown_title'),
          ),
        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(field),
      };

    case 'phone':
      return {
        title: t('common.phone'),
        key: 'phone',
        render: ({ fields }: Entry) => {
          const onClick = () => {
            if (fields?.phone) {
              window.open(`https://wa.me/${fields.phone}`, '_blank');
            }
          };

          return (
            <Flex justifyContent="space-between" gap="l">
              {getTruncatedCell(fields?.phone)}
              {fields?.phone ? (
                <Tooltip
                  title={t('entries.table.settings.columns.whatsapp.title')}
                >
                  <Button
                    onClick={onClick}
                    backgroundColor="transparent"
                    padding="0"
                    margin="0"
                  >
                    <AtiraIcon icon={faWhatsapp} color="green" size="2x" />
                  </Button>
                </Tooltip>
              ) : null}
            </Flex>
          );
        },
        sortable: true,
        reorder: true,
        width: '12rem',
        data: column,
      };

    case 'email':
      return {
        title: t('common.email'),
        key: 'email',
        render: ({ fields }: Entry) => {
          const onClick = () => {
            if (fields?.email) {
              window.open(`mailto:${fields.email}`, '_blank');
            }
          };

          return (
            <Flex justifyContent="space-between" gap="l">
              {getTruncatedCell(fields?.email)}
              {fields?.email ? (
                <Tooltip
                  title={t('entries.table.settings.columns.email.title')}
                >
                  <Button
                    onClick={onClick}
                    backgroundColor="transparent"
                    padding="0"
                    margin="0"
                  >
                    <AtiraIcon icon={faEnvelope} color="black" size="2x" />
                  </Button>
                </Tooltip>
              ) : null}
            </Flex>
          );
        },
        sorter: (a: Entry, b: Entry) =>
          a.fields?.email.localeCompare(b.fields?.email),
        sortable: true,
        reorder: true,
        data: column,
        width: '17rem',
      };

    default:
      return {
        title: t(`common.${field}`),
        key: field,
        render: (entry: Entry) => {
          const value =
            (
              entry[field as keyof Entry] ||
              entry.fields?.[field as keyof Entry['fields']]
            )?.toString() || '';

          return getTruncatedCell(value);
        },
        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(field),
      };
  }
};

export function createEntriesTableColumns<T>(
  columns: EntriesTableSettings['columns'],
  functions: FieldFunctions,
) {
  return columns.map((c) =>
    getFieldInfo(c, functions),
  ) as TableProps<T>['columns'];
}
