import { Table, TableProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppLangs } from '../i18n';
import { Entry } from '../model/entry/Entry';
import { Flex } from './Flex';

interface AtiraTableProps<T extends object> extends TableProps {
  data: T[];
  columns: TableProps['columns'];
  loading?: boolean;
  selectedRowKeys?: React.Key[];
  onSelectedRowKeysChange?: ATVoidFunction<React.Key[]>;
}

const StyledTableWrapper = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid black;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td:not(:last-child) {
    border-right: 0.01rem solid lightgrey;
  }
`;

export function AtiraTable<T extends Entry>({
  data,
  columns,
  loading = false,
  selectedRowKeys,
  onSelectedRowKeysChange,
  ...props
}: AtiraTableProps<T>) {
  const { t, i18n } = useTranslation();

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: Entry[]) =>
      onSelectedRowKeysChange?.(newSelectedRowKeys),
  };

  return (
    <Flex flexDirection="column" width="100%">
      <StyledTableWrapper>
        <Table
          dataSource={data.map((x) => ({ ...x, key: x._id }))}
          rowSelection={rowSelection}
          columns={columns?.filter(({ data: { enabled } }: any) => enabled)}
          locale={{ emptyText: t('entries.no_entries') }}
          scroll={{ x: 'max-content' }}
          loading={loading}
          style={{
            overflowY: 'hidden',
            height: '100%',
          }}
          rowHoverable
          direction={i18n.language === AppLangs.AR ? 'rtl' : 'ltr'}
          {...props}
        />
      </StyledTableWrapper>
    </Flex>
  );
}
