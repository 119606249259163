export const standardDate = (createdAt: string | Date) => {
  if (!createdAt) {
    return '';
  }

  return new Date(createdAt).toLocaleDateString('tr-TR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
};
