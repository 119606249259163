import pick from 'lodash/pick';
import styled, { CSSProperties } from 'styled-components';

import { Spacing } from '../theme/Spacing';

type FlexProps = Pick<
  CSSProperties,
  | 'flex'
  | 'flexDirection'
  | 'flexBasis'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexWrap'
  | 'alignItems'
  | 'alignSelf'
  | 'justifyContent'
  | 'alignContent'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'height'
  | 'maxHeight'
  | 'minHeight'
  | 'position'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'transform'
  | 'cursor'
  | 'border'
  | 'borderTop'
  | 'borderLeft'
  | 'borderBottom'
  | 'borderRight'
  | 'borderRadius'
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomLeftRadius'
  | 'borderBottomRightRadius'
  | 'overflow'
  | 'overflowY'
  | 'overflowX'
  | 'opacity'
  | 'background'
  | 'boxSizing'
> & {
  className?: string;
  gap?: keyof typeof Spacing;
  margin?: keyof typeof Spacing;
  marginTop?: keyof typeof Spacing;
  marginBottom?: keyof typeof Spacing;
  marginLeft?: keyof typeof Spacing;
  marginRight?: keyof typeof Spacing;
  cursor?: CSSProperties['cursor'];
  padding?: keyof typeof Spacing;
  paddingTop?: keyof typeof Spacing;
  paddingBottom?: keyof typeof Spacing;
  paddingLeft?: keyof typeof Spacing;
  paddingRight?: keyof typeof Spacing;
  backgroundColor?: CSSProperties['backgroundColor'];
};

const spacingKeys: (keyof FlexProps)[] = [
  'top',
  'bottom',
  'left',
  'right',
  'gap',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
];

export const Flex = styled.div.attrs(
  ({ backgroundColor, ...props }: FlexProps) => {
    const themable = Object.entries(pick(props, spacingKeys)).reduce(
      (style, prop) => ({
        ...style,
        // @ts-ignore
        [prop[0]]: Spacing[prop[1]],
      }),
      {},
    );

    return {
      style: {
        backgroundColor,
        ...(props as FlexProps),
        ...themable,
      },
    };
  },
)<FlexProps>`
  display: flex;
`;
